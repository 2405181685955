import { TextField } from "@mui/material";
import { UseFormRegister } from 'react-hook-form';
import { EconomicCurrencyInput } from "..";

interface ICustomTextFieldProps {
    name: string;
    getValues: any;
    register: UseFormRegister<any>;
    productStatus: number;
}

export default function CustomTextField({ name, register, getValues, productStatus }: ICustomTextFieldProps) {
    return (
        <TextField
            {...register(name)}
            value={getValues(name)}
            InputProps={{
                inputComponent: EconomicCurrencyInput as any
            }}
            disabled={productStatus != 0}
        />
    )
}