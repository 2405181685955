import "./style.css"
import { useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, useParams, useLocation } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { Loader } from "rsuite";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FiPositioningTab } from "../../../components/Projects/FiPositioningTab";
import { TabComponent } from "../../../components/Shared/TabComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { FiPositioningFormProps, InternalProjectsFormProps, ProjectDescriptionFormProps } from "./Interfaces/InternalProjectsFormProps";
import { schemaInternalProjects } from "../../../schemas/createProjectSchema";
import { schemaFiPositioning } from "../../../schemas/createFiPositioningSchema";
import { IResponseProps } from "../../../interfaces/IResponseProps";
import { GetByIdAPI, GetByIdLastYearAPI } from "../../../API/InternalProjectsAPI";
import { schemaProjectDescription } from "../../../schemas/createProjectDescription";
import { ProjectDescriptionTab } from "../../../components/Projects/ProjectDescriptionTab";
import { AnexoTab } from "../../../components/Projects/AnexoTab";
import { ProjectInformationTab } from "../../../components/Projects/ProjectInformationTab";
import { ProjectLastYearTab } from "../../../components/Projects/ProjectLastYearTab";
import { InternalProjectsModel } from "../../../models/projectModel";

export default function InternalProductsTabs() {
    const [loading, setLoading] = useState(false);
    const { company } = useCompany();
    const { lastYearProject, lastYearProjectImport, id, lastYearId, folderId } = useParams();
    const [isDisabledTabInternalProjects, setIsDisabledTabInternalProjects] = useState(true)
    const [isDisabledTabFiPoistioning, setIsDisabledTabFiPoistioning] = useState(true)
    const [subScreenTitle, setSubScreenTitle] = useState('')
    const location = useLocation();

    const path = location.pathname.split('/').slice(0, -2).join('/');

    const internalProjectsLastYearTabForm = useForm<InternalProjectsFormProps>({
        defaultValues: {} as InternalProjectsFormProps,
        resolver: yupResolver(schemaInternalProjects)
    });

    const internalProjectsTabForm = useForm<InternalProjectsFormProps>({
        defaultValues: {} as InternalProjectsFormProps,
        resolver: yupResolver(schemaInternalProjects)
    });

    const fiPoistioningTabForm = useForm<FiPositioningFormProps>({
        defaultValues: {} as FiPositioningFormProps,
        resolver: yupResolver(schemaFiPositioning)
    });

    const projectDescriptionTabForm = useForm<ProjectDescriptionFormProps>({
        defaultValues: {} as ProjectDescriptionFormProps,
        resolver: yupResolver(schemaProjectDescription)
    });


    const getProject = async () => {
        if (id) {
            await GetByIdAPI(id).then((resp: IResponseProps) => {

                if (resp.statusCode == 200) {
                    var data = resp.data as InternalProjectsModel;

                    if (data != null) {
                        internalProjectsTabForm.reset(data);
                        fiPoistioningTabForm.reset(data);
                        projectDescriptionTabForm.reset(data);
                    }
                }
            });
        }
    }

    const getProjectLastYear = async () => {
        if (lastYearId && (lastYearId != null || lastYearId != "null") && lastYearId !== "0") {
            GetByProjectLastYearById(lastYearId);
        }
    }

    const GetByProjectLastYearImportedById = async (projectLastId: string) => {
        await GetByIdAPI(projectLastId).then((resp: IResponseProps) => {
            if (resp.statusCode == 200) {
                var data = resp.data as InternalProjectsModel

                if (data != null) {
                    internalProjectsLastYearTabForm.reset(data);

                    if (lastYearProjectImport === "1" && id == undefined) {
                        internalProjectsTabForm.reset(data);
                        fiPoistioningTabForm.reset(data);
                        projectDescriptionTabForm.reset(data);
                    }
                }
            }
        });
    }

    const GetByProjectLastYearById = async (projectLastId: string) => {
        await GetByIdLastYearAPI(projectLastId).then((resp: IResponseProps) => {
            if (resp.statusCode == 200) {
                var data = resp.data as InternalProjectsModel

                (data != null)
                    ? internalProjectsLastYearTabForm.reset(resp.data as InternalProjectsModel)
                    : GetByProjectLastYearImportedById(projectLastId);
            }
        });
    }

    const validateInternalProjectsTabs = async (data: InternalProjectsFormProps) => {
        (data.projectName !== "" &&
            data.startDate !== null &&
            data.endDate !== null &&
            data.identification !== "" &&
            data.acronym !== "" &&
            data.typology !== null &&
            data.projectToExchangePPBforRD !== null &&
            data.ranges !== null &&
            data.coverage !== null &&
            data.development !== null &&
            data.economicActivity !== "" &&
            data.hasPublications !== null &&
            data.hasIntellectualProperty !== null &&
            data.nature !== null &&
            data.name !== null &&
            data.hasCpf !== null &&
            data.telephone !== "" &&
            data.phoneType !== null &&
            data.projectScope !== "" &&
            data.developmentBaseYear !== "" &&
            data.stepsForeseenProject !== "" &&
            data.scientificTechnicalProblem !== "") ? setIsDisabledTabInternalProjects(false) : setIsDisabledTabInternalProjects(true);
    }

    const validateFiPositioningTabs = async (typology: number | null, data: FiPositioningFormProps) => {
        if (typology == 5) {
            (data.criterionCFrameworkAnalysis == 0 && data.projectIsTic != null && data.content != null && data.level != null && data.trainedOrQualifiedPersonnel != null && data.observation != null)
                ? setIsDisabledTabFiPoistioning(false)
                : setIsDisabledTabFiPoistioning(true);
        } else {
            (data.criterionCFrameworkAnalysis == 0 && data.projectIsTic != null && data.technicalScientificProblem != null && data.contextualizationOfTheSteps != null
                && data.investigativeActivity != null && data.noveltyElement != null && data.observation != "")
                ? setIsDisabledTabFiPoistioning(false)
                : setIsDisabledTabFiPoistioning(true);
        }
    }

    const getProjects = async () => {
        setLoading(true);
        try {
            await getProjectLastYear();
            await getProject();
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getProjects();


    }, [id]);

    useEffect(() => {
        if (id) {
            const project = internalProjectsTabForm.getValues();
            validateInternalProjectsTabs(project);
            validateFiPositioningTabs(project.typology, fiPoistioningTabForm.getValues());
        }
    }, [internalProjectsTabForm.watch()])

    useEffect(() => {
        if (!folderId)
            setSubScreenTitle('');
    }, [location])

    return (
        <>
            {loading && < Loader />}
            <div className='card'>
                <div className='card-header header-backgroud'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center font-color-notSelect'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>
                            <Link to={`/${company.serviceId}/internalProjects`}>
                                Análise de Elegibilidade
                            </Link>
                        </span>
                        {subScreenTitle != ''
                            ?
                            <>
                                <span className='breadCrumb-item-actual'>{' > '}
                                    <Link to={path}>
                                        Criação de Projeto
                                    </Link>
                                </span>
                                <span className='breadCrumb-item-actual'>{' > '}
                                    {subScreenTitle}
                                </span>
                            </>
                            :
                            <span className='breadCrumb-item-actual'>{' > '}
                                Criação de Projeto
                            </span>
                        }

                    </div>
                </div>
                <div className='card-body'>
                    <TabComponent tabs={lastYearProject === '1'
                        ? [
                            { label: "Projeto do Ano Anterior", content: <ProjectLastYearTab InternalProjectsLastYearForm={internalProjectsLastYearTabForm} isDisabledField={true} />, isDisabled: false },
                            { label: "Informações do Projeto", content: <ProjectInformationTab internalProjectsTabForm={internalProjectsTabForm} />, isDisabled: false },
                            { label: "Parecer FI Group", content: <FiPositioningTab fiPoistioningTabForm={fiPoistioningTabForm} />, isDisabled: isDisabledTabInternalProjects },
                            { label: "Descrição do Projeto", content: <ProjectDescriptionTab projectDescriptionTabForm={projectDescriptionTabForm} />, isDisabled: isDisabledTabFiPoistioning },
                            { label: "Anexo", content: <AnexoTab setSubScreenTitle={setSubScreenTitle} />, isDisabled: id ? false : true }
                        ]
                        :
                        [
                            { label: "Informações do Projeto", content: <ProjectInformationTab internalProjectsTabForm={internalProjectsTabForm} />, isDisabled: false },
                            { label: "Parecer FI Group", content: <FiPositioningTab fiPoistioningTabForm={fiPoistioningTabForm} />, isDisabled: isDisabledTabInternalProjects },
                            { label: "Descrição do Projeto", content: <ProjectDescriptionTab projectDescriptionTabForm={projectDescriptionTabForm} />, isDisabled: isDisabledTabFiPoistioning },
                            { label: "Anexo", content: <AnexoTab setSubScreenTitle={setSubScreenTitle} />, isDisabled: id ? false : true }
                        ]
                    } />
                </div>
            </div>
        </>
    )
}