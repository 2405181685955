import { Control, FieldError, UseFormGetValues, UseFormRegister } from "react-hook-form"
import { TextFieldCustom } from "../TextFieldCustom"
import { ExpandMore } from "@mui/icons-material"
import { AccordionSummary, Typography, AccordionDetails, Accordion } from "@mui/material"
import { AiFillInfoCircle } from "react-icons/ai"


interface IAccordionTabProps {
    title: string,
    label?: string,
    field: string,
    control: Control<any, any>,
    fieldErrors?: any | FieldError | undefined,
    register: UseFormRegister<any>,
    getValues: UseFormGetValues<any>,
    isDisabled?: boolean,
    isVisible?:string
}

export function AccordionCustom({ title, field, control, fieldErrors, register, getValues, isDisabled = false, isVisible }: IAccordionTabProps) {

    if(isVisible != undefined && getValues){
        if(getValues(isVisible) == 0){
            isDisabled = true
        }  
    }

    return (
        <Accordion>
            <div className="text-title">
                <AccordionSummary aria-controls={`${field}-content`} id={`${field}-header`} expandIcon={<ExpandMore />}>
                    <Typography>{title}
                        <AiFillInfoCircle size={15} className="ms-1 info-color" />
                    </Typography>
                </AccordionSummary>
            </div>
            <AccordionDetails>
                <Typography>
                    <div className="row mb-2 mt-2">
                        <div className='col-12'>
                            <TextFieldCustom
                                field={field}
                                control={control}
                                fieldErrors={fieldErrors}
                                register={register}
                                multiline={true}
                                rows={4}
                                getValues={getValues}
                                isDisabled={isDisabled}
                            />
                        </div>
                    </div>
                    <div className="row mb-3"></div>
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}