import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiFillHome, AiOutlineClose } from 'react-icons/ai';
import { FaBuilding } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useCompany } from '../../../hooks/useCompany';
import { api } from '../../../services/axios';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import './styles.css';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import BranchCompany from "../BranchCompany";
import { TextField, Button } from '@mui/material';
import Loader from '../../../components/Shared/Loader';
import { useCan } from '../../../hooks/useCan';
import { ExternalRoles } from '../../../Static/Roles';

interface CompanyProps {
    id: string;
    region: string;
    uf: string;
    cep: string;
    address: string;
    sector: string;
    description: string;
    city: string;
    photoUrl: string;
    cnpj: string;
}
interface Address {
    bairro: string;
    cep: string;
    complemento: string;
    ddd: string;
    gia: string;
    ibge: string;
    localidade: string;
    logradouro: string;
    siafi: string;
    uf: string;
}
interface UpdateCompanyHeaderProps {
    id: string;
    serviceId: string;
    region: string;
    uf: string;
    cep: string;
    address: string;
    city: string;
    sector: string;
    description: string;
    cnpj: string;
}

const schema = yup.object().shape({
    cep: yup
        .string()
        .required('O Campo CEP é obrigatório')
        .trim()
        .max(8, 'Por favor, insira um valor válido para o campo CEP')
        .min(8, 'Por favor, insira um valor válido para o campo CEP'),

    city: yup
        .string()
        .required('O Campo Município é obrigatório')
        .trim(),

    // address: yup
    //     .string()
    //     .required('O Campo Endereço é obrigatório')
    //     .trim(),

    uf: yup
        .string()
        .required('O Campo UF é obrigatório')
        .trim(),

    region: yup
        .string()
        .required('O Campo Região é obrigatório')
        .trim(),

    // description: yup
    //     .string()
    //     .required('O Campo Descrição é obrigatório')
    //     .trim()
    //     .max(255, 'Por favor, insira um valor entre 5 e 255 caracteres')
    //     .min(5, 'Por favor, insira um valor entre 5 e 255 caracteres'),

    sector: yup
        .string()
        .required('O Campo Setor é obrigatório')
        .trim(),

    cnpj: yup
        .string()
        .required('O CNPJ é obrigatório')
        .trim()

});

export function EditCompany() {
    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    const permissionTechnician = useCan({ rolesCan: ["Technician"] });
    const permissionMaster = useCan({ rolesCan: ["Master"] });
    const permissionExternal = useCan({ rolesCan: [...ExternalRoles] });

    var hasPermition = false

    if (permissionFinancial || permissionTechnician || permissionMaster) {
        hasPermition = true
       
    }
 
    const [loading, setLoading] = useState(false);
    const [companyData, setCompanyData] = useState<CompanyProps>();
    const [cep, setCep] = useState('');
    const [city, setCity] = useState('');
    const [uf, setUf] = useState('');
    const [showDropzone, setShowDropzone] = useState(true);
    const [wasAltered, setWasAltered] = useState<boolean>(false);
    const [filePreview, setFilePreview] = useState("");
    const { company, changeCompanyPhoto } = useCompany();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg']
        },
        maxFiles: 1,
        onDrop: files => onDropImage(files)
    });

    const normalized = require('normalize-strings');

    const [address, setAddress] = useState<Address>({
        bairro: '',
        cep: '',
        complemento: '',
        ddd: '',
        gia: '',
        ibge: '',
        localidade: '',
        logradouro: '',
        siafi: '',
        uf: ''
    });

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm<UpdateCompanyHeaderProps>({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        async function getCompany() {
            setLoading(true);
            try {
                const result = await api.get(`company/Get/${company.id}`);
                const response = await result.data;


                if (response.statusCode === 200) {
                    const data: CompanyProps = response.data;

                    setValue('address', data.address);
                    setValue('cep', data.cep);
                    setValue('city', data.city);
                    setValue('region', data.region);
                    setValue('uf', data.uf);
                    setValue('sector', data.sector);
                    setValue('description', data.description);
                    setValue('cnpj', company.taxNumber);

                    if (data.photoUrl) {
                        setFilePreview(data.photoUrl);
                        setShowDropzone(false);
                    }
                    else {
                        setShowDropzone(true);
                    }

                    setCompanyData(data);
                }
            }
            catch (err: any) {

            }
            finally {
                setLoading(false);
            }
        }

        getCompany();
    }, []);

    async function getDataByCEP() {
        setLoading(true);
        try {
            const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
            if (response.data.hasOwnProperty('erro')) {
                if (response.data.erro === true) {
                    toast.error('CEP não encontrado!');
                }
            }
            if (response.status === 200) {
                const data: Address = await response.data;
                setValue('address', data.logradouro);
                setValue('city', data.localidade);
                setValue('uf', data.uf);
                setCity(data.localidade);
                setUf(data.uf);
                setAddress(data);

            }
        }
        catch (err: any) {
            toast.error('Erro ao buscar o endereço solicitado, por favor verifique se o CEP está correto!');
            setValue('address', '');
            setValue('city', '');
            setValue('region', '');
            setValue('uf', '');
            setUf('');
            setCity('');
        }
        finally {
            setLoading(false);
            if (wasAltered) {
                setWasAltered(false);
            }
        }

    }
    async function getRegion() {
        var normalizedCity = normalized(city).replaceAll(' ', '').toUpperCase();
        await api.get(`companyRegion/getRegion/${normalizedCity}/${uf}`).then(resp => {
            setValue('region', resp.data.data.region);
        });

    }
    useEffect(() => {
        if (uf != '' && city != '')
            getRegion();
    }, [uf, city]);

    async function saveChanges(data: UpdateCompanyHeaderProps) {
        data.id = company.id;
        data.serviceId = company.serviceId;
        setLoading(true);
        if (filePreview == "") {
            toast.error('Por favor, insira uma imagem para continuar!');
            setLoading(false);
            return;
        }
        try {
            await api.put(`company/put`, data).then(async resp => {
                const response = resp.data;
                if (response.statusCode === 200) {
                    toast.success('Dados alterados com sucesso!');
                    await uploadFile(data.id);
                }
            });

        }
        catch (err: any) {
        }
        finally {
            setLoading(false);
        }
    }

    async function uploadFile(id: string) {
        setLoading(true);
        let blob: Blob = acceptedFiles[0];
        try {
            if (blob != undefined) {
                let formData = new FormData();
                formData.append('id', id);
                formData.append('serviceId', company.serviceId);
                formData.append('file', blob);
                const result = await api.post('company/uploadfile', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const response = await result.data;
                const data = response.data;
                if (response.statusCode === 200) {
                    changeCompanyPhoto(data.photoUrl);
                    toast.success('Upload da imagem feito com sucesso!');
                }
            }

        }
        catch (err: any) {
            console.log('UPLOAD FILE ERROR', err);
        }
        finally {
            setLoading(false);
        }
    }

    function onDropImage(files: any) {
        let file = files[0]
        if (file && validateFileType(file)) {
            setShowDropzone(false);
            setFilePreview(URL.createObjectURL(file));
        }
        else {
            toast.error('Por favor, insira um documento do tipo imagem, os tipos válidos são : .png, .jpeg, .jpg, .svg e .gif');
        }

    }

    function validateFileType(file: any) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml', 'image/gif'];
        return allowedTypes.includes(file.type);
    }

    function handleRemoveFile() {
        setShowDropzone(true);
        setFilePreview("");
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card'>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>Editar informações da empresa</span>
                    </div>
                </div>
                <div className='bg-white w-100 h-100 p-4 border'>
                    {/* <div className='modal-custom-header mt-3'>
                        <FaBuilding size={70} />
                        <h3 className='mt-3'>
                            <strong>
                                Editar Empresa
                            </strong>
                        </h3>
                        <p>Para editar a empresa, insira as devidas informações</p>
                    </div> */}
                    <form className='' onSubmit={handleSubmit(saveChanges)}>
                        {showDropzone
                            ?
                            <section className="container mb-4">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <p>Selecione uma imagem para compor a logo da empresa</p>
                                    <p><small>.png, .jpeg, .jpg, .gif</small></p>
                                </div>
                            </section>
                            :
                            <div className='image-container-preview mb-4 border rounded'>
                                <AiOutlineClose size={25} className='cursor-pointer' onClick={handleRemoveFile} />
                                <img src={filePreview} alt="" className='img-fluid' />
                            </div>
                        }
                        {hasPermition ?
                            <><div className='row mb-4'>
                                <div className='col-6'>
                                    <label className='form-label'></label>
                                    <TextField label="Razão Social" type="text" className='form form-control' defaultValue={company.title} disabled />
                                </div>
                                <div className='col-6'>
                                    <label className='form-label'></label>
                                    <TextField label="CNPJ" type="text"
                                        className='form form-control cursor-not-allowed'
                                        {...register('cnpj')}
                                        defaultValue={companyData?.cnpj}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        disabled />
                                    <span className='error-field'>{errors?.cnpj?.message}</span>
                                </div>
                            </div><div className='row mb-4'>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField
                                            label="CEP"
                                            type="text"
                                            placeholder='Digite o CEP...'
                                            className='form form-control'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                            defaultValue={companyData?.cep}
                                            {...register('cep', {
                                                onChange: e => { setCep(e.target.value); !wasAltered && setWasAltered(true); },
                                                onBlur: () => wasAltered && getDataByCEP()
                                            })} />
                                        <span className='error-field'>{errors?.cep?.message}</span>
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="UF" type="text" className='form form-control cursor-not-allowed'
                                            {...register('uf')}
                                            defaultValue={companyData?.uf}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled />
                                        <span className='error-field'>{errors?.uf?.message}</span>
                                    </div>

                                </div><div className='row mb-4'>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="Município"
                                            type="text" className='form form-control cursor-not-allowed'
                                            {...register('city')}
                                            defaultValue={companyData?.city}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled />
                                        <span className='error-field'>{errors?.city?.message}</span>
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="Região" type='text' className='form-control cursor-not-allowed' {...register('region')} defaultValue={companyData?.region} InputLabelProps={{
                                            shrink: true,
                                        }} disabled />
                                        <span className='error-field'>{errors?.region?.message}</span>
                                    </div>

                                </div><div className='row mb-4'>
                                    <div className='col-12'>
                                        <label className='form-label'></label>
                                        <TextField label="Setor"
                                            type="text"
                                            placeholder="Digite o Setor..."
                                            className='form form-control cursor-pointer'
                                            {...register('sector')}
                                            disabled
                                            InputLabelProps={{
                                                shrink: true,
                                            }} defaultValue={companyData?.sector} />
                                        <span className='error-field'>{errors?.sector?.message}</span>
                                    </div>
                                </div></>
                            :
                            <>
                                <div className='row mb-4'>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="Razão Social" type="text" className='form form-control' defaultValue={company.title} disabled />
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="CNPJ" type="text"
                                            className='form form-control cursor-not-allowed'
                                            {...register('cnpj')}
                                            defaultValue={companyData?.cnpj}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled />
                                        <span className='error-field'>{errors?.cnpj?.message}</span>
                                    </div>
                                </div><div className='row mb-4'>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField
                                            label="CEP"
                                            type="text"
                                            placeholder='Digite o CEP...'
                                            className='form form-control'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled={permissionFinancial}
                                            defaultValue={companyData?.cep}
                                            {...register('cep', {
                                                onChange: e => { setCep(e.target.value); !wasAltered && setWasAltered(true); },
                                                onBlur: () => wasAltered && getDataByCEP()
                                            })} />
                                        <span className='error-field'>{errors?.cep?.message}</span>
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="UF" type="text" className='form form-control cursor-not-allowed'
                                            {...register('uf')}
                                            defaultValue={companyData?.uf}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled />
                                        <span className='error-field'>{errors?.uf?.message}</span>
                                    </div>

                                </div><div className='row mb-4'>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="Município"
                                            type="text" className='form form-control cursor-not-allowed'
                                            {...register('city')}
                                            defaultValue={companyData?.city}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled />
                                        <span className='error-field'>{errors?.city?.message}</span>
                                    </div>
                                    <div className='col-6'>
                                        <label className='form-label'></label>
                                        <TextField label="Região" type='text' className='form-control cursor-not-allowed' {...register('region')} defaultValue={companyData?.region} InputLabelProps={{
                                            shrink: true,
                                        }} disabled />
                                        <span className='error-field'>{errors?.region?.message}</span>
                                    </div>

                                </div><div className='row mb-4'>
                                    <div className='col-12'>
                                        <label className='form-label'></label>
                                        <TextField label="Setor"
                                            type="text"
                                            placeholder="Digite o Setor..."
                                            className='form form-control cursor-pointer'
                                            {...register('sector')}
                                            disabled={permissionFinancial}
                                            InputLabelProps={{
                                                shrink: true,
                                            }} defaultValue={companyData?.sector} />
                                        <span className='error-field'>{errors?.sector?.message}</span>
                                    </div>
                                </div></>
                        }
                        {!hasPermition &&
                            <div className='row mb-4 mt-4'>
                                <div className='d-flex justify-content-center align-items-center mb-3'>
                                    <Button variant="contained" className='rounded forms-control' type='submit'>
                                        Salvar Informações
                                    </Button>
                                </div>
                            </div>
                        }
                        <div className='row mb-4 mt-4'>
                            <BranchCompany companyId={company.companyId} serviceId={company.serviceId} companyCnpj={company.taxNumber} />
                            <div className='d-flex align-items-center justify-content-between mt-4'>
                                {!permissionExternal &&
                                    <Link className='w-25' to={`/${company.serviceId}/contractModule`}>Gestão de Contratos</Link>
                                }
                                {company.cfopIsCustom && !hasPermition ?
                                    <Link className='w-25 text-end' to={`/${company.companyId}/CFOPCustom`}> Visualizar CFOPs consideradasf </Link>
                                    :
                                    <div></div>
                                }
                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </>
    );
}