import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../components/Shared/Loader";
import { api } from "../../../services/axios";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { CustomControlledCurrencyInput } from "../../Shared/CustomControlledCurrencyInput";
import { formatUSDCurrencyToFloat, formatBRLCurrencyToFloat } from "../../../utils/formatCurrency";
import { Button, TextField } from "@mui/material";
import { useCompany } from "../../../hooks/useCompany";
import { EconomicCurrencyInput } from "../../Shared/CustomCurrencyInput";
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import CustomTextField from "../../Shared/CustomCurrencyInput/CustomTextField";


const dataSchema = yup.object().shape({
    quantityProduced: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    grossRevenue: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    exports: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    importedComponents: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    additionalInvestments: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    additionalQuantity: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
    yearBased: yup.number().nullable(true).transform((_, val) => val !== '' && val ? formatBRLCurrencyToFloat(val) : null),
});

const schema = yup.object().shape({
    lastYear: dataSchema.nullable(true),
    baseYear: dataSchema.nullable(true),
    nextYear: dataSchema.nullable(true)
});

interface EconomicDataProps {
    id: string;
    quantityProduced: number | null;
    grossRevenue: number | null;
    exports: number | null;
    importedComponents: number | null;
    additionalInvestments: number | null;
    additionalQuantity: number | null;
    yearBased: number | null;
}

export interface EconomicDataFormsProps {
    productId: string;
    serviceId: string;
    lastYear: EconomicDataProps;
    baseYear: EconomicDataProps;
    nextYear: EconomicDataProps;
}

interface PreLoadEconomicDataProps {
    economicData: EconomicDataFormsProps;
    show: boolean;
    productStatus: number;
}

export default function EconomicDataProduct({ economicData, show, productStatus }: PreLoadEconomicDataProps) {
    const [loading, setLoading] = useState(false);
    const { company } = useCompany();

    const { handleSubmit, register, formState: { errors } } = useForm<EconomicDataFormsProps>({
        resolver: yupResolver(schema),
        defaultValues: economicData
    });

    const { getValues } = useForm<EconomicDataFormsProps>({
        defaultValues: economicData
    });

    async function handleCreateEconomicData(data: EconomicDataFormsProps) {
        setLoading(true);
        data.productId = economicData.productId;
        data.serviceId = company.serviceId;

        data.lastYear.yearBased = 0;
        data.baseYear.yearBased = 1;
        data.nextYear.yearBased = 2;

        try {
            const result = await api.post('economicdata/post', data);

            if (result.data.statusCode === 200)
                toast.success('Dados Econômicos Lançados com Sucesso!');
        }
        catch (error: any) {
            console.log('CREATE ECONOMIC DATA ERROR', error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleUpdateEconomicData(data: EconomicDataFormsProps) {
        setLoading(true);
        data.productId = economicData.productId;
        data.serviceId = company.serviceId;

        data.lastYear.yearBased = 0;
        data.baseYear.yearBased = 1;
        data.nextYear.yearBased = 2;

        try {
            const result = await api.put('economicdata/put', data);

            if (result.data.statusCode === 200)
                toast.success('Dados Econômicos Lançados com Sucesso!');
        }
        catch (error: any) {
            console.log('EDIT ECONOMIC DATA ERROR', error);
        }
        finally {
            setLoading(false);
        }
    }

    const currencyInputList = [
        { title: 'Partes e Componentes Importados (U$)', property: 'importedComponents' },
        { title: 'Faturamento Bruto (R$)', property: 'grossRevenue' },
        { title: 'Exportações (U$)', property: 'exports' },
        { title: 'Investimentos Adicionais para a Produção (R$)', property: 'additionalInvestments' },
        { title: 'Quantidade Produzida', property: 'quantityProduced' },
        { title: 'Quantidade Adicional de Pessoal Direto na Fábrica', property: 'additionalQuantity' },
    ]
    const yearList = ['lastYear', 'baseYear', 'nextYear']

    return (
        <>
            {loading && <Loader />}
            <div className={`card-body bg-light w-100 h-100 p-4 border ${show ? '' : 'd-none'}`}>
                <form id='form-economic' onSubmit={economicData?.nextYear == null ? handleSubmit(handleCreateEconomicData) : handleSubmit(handleUpdateEconomicData)}>
                    <div className='d-flex justify-content-start mb-5'>
                        <h4 className="title">Dados Econômicos</h4>
                    </div>
                    <div className='d-flex justify-content-end mb-3'>
                        <div className='col-2 p-2'>
                            <h5>Ano Anterior</h5>
                        </div>
                        <div className='col-2 p-2'>
                            <h5>Ano Corrente</h5>
                        </div>
                        <div className='col-2 p-2'>
                            <h5>Próximo Ano</h5>
                        </div>
                    </div>
                    {currencyInputList.map((item, index) => {
                        return (
                            <div className='d-flex justify-content-end mb-3' key={index}>
                                <div className='d-flex justify-content-end mb-3'>
                                    <h5 className='p-2'>{item.title}</h5>
                                </div>
                                {yearList.map((year, i) => {
                                    return (

                                        <div className='col-2 p-2' key={i}>
                                            <CustomTextField name={year + "." + item.property} getValues={getValues} register={register} productStatus={productStatus}/>

                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    {productStatus == 0 &&
                        <Button variant="contained" className='btn btn-success w-100 mt-4' type='submit'>Salvar Alterações</Button>
                    }
                </form>
            </div>
        </>
    );
}
