import { UseFormReturn } from "react-hook-form";
import { InternalProjectsFormProps } from "../../../pages/Projects/InternalProjectsTabs/Interfaces/InternalProjectsFormProps";
import { TextFieldCustom } from "../../Shared/InternalProjects/TextFieldCustom";
import { DatePickerCustom } from "../../Shared/InternalProjects/DatePickerCustom";
import { SelectCustom } from "../../Shared/InternalProjects/SelectCustom";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { typologyList, projectToExchangePPBforRDList, rangesList, coverageList, developmentlist, naturelist, phoneTypeList, radioGrouplist } from "../../../Static/InternalProjectsStatic";
import { ExpandMore } from "@mui/icons-material";
import { AccordionCustom } from "../../Shared/InternalProjects/AccordionCustom";
import { ButtonSubmitDefault } from "../../Shared/ButtonSubmitDefault/ButtonSubmitDefault";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useCompany } from "../../../hooks/useCompany";
import { toast } from "react-toastify";
import { InputCpfMaskCustom } from "../../InputsMasks/InputCpf";
import { InputCellMaskCustom } from "../../InputsMasks/InputCell/Index";


interface IInternalProjectsFormProps {
    InternalProjects: UseFormReturn<InternalProjectsFormProps, any>,
    Post(data: InternalProjectsFormProps): Promise<void>,
    Put(data: InternalProjectsFormProps): Promise<void>,
    isButtonSave: boolean,
    isEdition: boolean,
    isProjectLastYear: boolean,
    isDisabledField?: boolean,
}

export function ProjectTable({ InternalProjects, Post, Put, isButtonSave, isEdition, isProjectLastYear, isDisabledField = false }: IInternalProjectsFormProps) {
    const { register, handleSubmit, control, formState: { errors }, getValues, setValue } = InternalProjects;
    const { lastYearProjectImport, id } = useParams();

    const [isHasOther, setIsHasOther] = useState(true);
    const [isMobile, setIsMobile] = useState(false);
    const [expanded, setExpanded] = useState<string | false>('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { company } = useCompany();
    const [isCpfDisabled, setIsCpfDisabled] = useState(getValues('hasCpf'));

    async function handleCreateProject(data: InternalProjectsFormProps) {
        setLoading(true);

        try {
            data.serviceId = company.serviceId;
            data.hasPublications = data.hasPublications ? Boolean(data.hasPublications) : false;
            data.hasIntellectualProperty = data.hasIntellectualProperty ? Boolean(data.hasIntellectualProperty) : false;
            data.hasCpf = data.hasCpf ? Boolean(data.hasCpf) : false;

            data.startDate = data.startDate !== undefined ? data.startDate : null;
            data.endDate = data.endDate !== undefined ? data.endDate : null;

            data.typology = data.typology?.toString() !== "" ? Number(data.typology) : null;
            data.projectToExchangePPBforRD = data.projectToExchangePPBforRD?.toString() !== "" ? Number(data.projectToExchangePPBforRD) : null;
            data.ranges = data.ranges?.toString() !== "" ? Number(data.ranges) : null;
            data.coverage = data.coverage?.toString() !== "" ? Number(data.coverage) : null;
            data.development = data.development?.toString() !== "" ? Number(data.development) : null;
            data.nature = data.nature?.toString() !== "" ? Number(data.nature) : null;
            data.phoneType = data.phoneType?.toString() !== "" ? Number(data.phoneType) : null;
            data.other = data.other?.toString() !== "" ? data.other : null;

            // caso seja projeto do anterior
            data.internalProjectsId = (id != null) ? id : null;
            data.isLastYearProjectImport = !isEdition ? lastYearProjectImport == "1" ? true : false : false;

            isEdition ? await Put(data) : await Post(data);

        } catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function hasOtherChange() {
        const hasOther = getValues('typology') == 6 ? false : true;
        setIsHasOther(hasOther);

        if (hasOther) setValue('other', '');
    }

    async function hasCpfChange() {
        setIsCpfDisabled(getValues('hasCpf'));

        if (getValues('hasCpf')) setValue('cpf', null);
    }

    async function phoneTypeChange() {
        setIsMobile(getValues('phoneType') == 0 ? false : true);
    }

    async function handleKeyDown(event: any) {
        if (event.key === 'Enter') event.preventDefault();
    }

    return (
        <>
            <div className="card">
                <form className="card-body" onSubmit={handleSubmit(handleCreateProject)} onKeyDown={handleKeyDown}>
                    <div className="">
                        <div className="row mb-3">
                            <div className='col-6'>
                                <TextFieldCustom
                                    label="Nome do Projeto"
                                    field="projectName"
                                    control={control}
                                    fieldErrors={errors.projectName}
                                    register={register}
                                    getValues={getValues}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                            <div className='col-3'>
                                <DatePickerCustom
                                    label="Data Inicial"
                                    control={control}
                                    field="startDate"
                                    fieldErrors={errors.startDate}
                                    onChange={(e: any) => setValue('startDate', e != null ? new Date(e) : null)}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                            <div className='col-3'>
                                <DatePickerCustom
                                    label="Data Final"
                                    control={control}
                                    field="endDate"
                                    fieldErrors={errors.startDate}
                                    onChange={(e: any) => setValue('endDate', e != null ? new Date(e) : null)}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-9'>
                                <TextFieldCustom
                                    label="Identificação"
                                    field="identification"
                                    control={control}
                                    fieldErrors={errors.identification}
                                    register={register}
                                    getValues={getValues}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                            <div className='col-3'>
                                <TextFieldCustom
                                    label="Sigla"
                                    field="acronym"
                                    control={control}
                                    fieldErrors={errors.acronym}
                                    register={register}
                                    getValues={getValues}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-6">
                                <SelectCustom
                                    label="Tipo"
                                    field="typology"
                                    control={control}
                                    fieldErrors={errors.typology}
                                    register={register}
                                    defaultValue={getValues('typology')?.toString() ?? null}
                                    list={typologyList}
                                    haschange={hasOtherChange}
                                    isDisabled={isDisabledField}
                                    getValues={getValues}
                                    isVisible="criterionCFrameworkAnalysis"
                                />
                            </div>
                            <div className="col-6">
                                <TextFieldCustom
                                    label="Outro ( Especificar)"
                                    field="other"
                                    control={control}
                                    fieldErrors={errors.other}
                                    register={register}
                                    getValues={getValues}
                                    isDisabled={isHasOther || isDisabledField}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='col-6'>
                                <SelectCustom
                                    label="Projeto para Cumprir Troca de PPB por P&D?"
                                    field="projectToExchangePPBforRD"
                                    control={control}
                                    fieldErrors={errors.projectToExchangePPBforRD}
                                    register={register}
                                    defaultValue={getValues('projectToExchangePPBforRD')?.toString() ?? null}
                                    list={projectToExchangePPBforRDList}
                                    haschange={() => { }}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                            <div className='col-6'>
                                <SelectCustom
                                    label="Alcance"
                                    field="ranges"
                                    control={control}
                                    fieldErrors={errors.ranges}
                                    register={register}
                                    defaultValue={getValues('ranges')?.toString() ?? null}
                                    list={rangesList}
                                    haschange={() => { }}
                                    isDisabled={isDisabledField}
                                />
                            </div>
                        </div>
                        <div className="mb-4">
                            <Accordion>
                                <div className="text-title">
                                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMore />}>
                                        <Typography component={'span'} variant={'body2'}>Grau de Inovação</Typography>
                                    </AccordionSummary>
                                </div>
                                <AccordionDetails>
                                    <Typography component={'span'} variant={'body2'}>
                                        <div className="row mb-1 mt-2">
                                            <div className='col-6'>
                                                <SelectCustom
                                                    label="Abrangência"
                                                    field="coverage"
                                                    control={control}
                                                    fieldErrors={errors.coverage}
                                                    register={register}
                                                    defaultValue={getValues('coverage')?.toString() ?? null}
                                                    list={coverageList}
                                                    haschange={() => { }}
                                                    isDisabled={isDisabledField}
                                                />
                                            </div>
                                            <div className='col-6'>
                                                <SelectCustom
                                                    label="Desenvolvimento"
                                                    field="development"
                                                    control={control}
                                                    fieldErrors={errors.development}
                                                    register={register}
                                                    defaultValue={getValues('development')?.toString() ?? null}
                                                    list={developmentlist}
                                                    haschange={() => { }}
                                                    isDisabled={isDisabledField}
                                                />
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="mb-4">
                            <Accordion >
                                <div className="text-title">
                                    <AccordionSummary
                                        aria-controls="panel2d-content"
                                        id="panel2d-header"
                                        expandIcon={<ExpandMore />}>
                                        <Typography component={'span'} variant={'body2'}>Área de Aplicação</Typography>
                                    </AccordionSummary>
                                </div>
                                <AccordionDetails>
                                    <Typography component={'span'} variant={'body2'}>
                                        <div className="row mb-1 mt-2 mb-2">
                                            <div className='col-12 mb-3'>
                                                <TextFieldCustom
                                                    label="Atividade Econômica"
                                                    field="economicActivity"
                                                    control={control}
                                                    fieldErrors={errors.economicActivity}
                                                    register={register}
                                                    getValues={getValues}
                                                    isDisabled={isDisabledField}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3 d-flex">
                                            <div className="col-3">
                                                <FormLabel id="demo-radio-buttons-group-label">Este projeto possui publicações?</FormLabel>
                                            </div>
                                            <div className="col-6">
                                                <FormControl>
                                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label"
                                                        {...register('hasPublications', { onChange: (e) => setValue('hasPublications', e.target.value === "true") })}
                                                        defaultValue={getValues('hasPublications') ?? "false"}
                                                    >
                                                        {radioGrouplist.map((p, i) => (
                                                            <FormControlLabel key={i} value={p.val} control={<Radio />} label={p.label} disabled={isDisabledField} />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                        <div className="row mb-3 d-flex">
                                            <div className="col-3">
                                                <FormLabel id="demo-radio-buttons-group-label">Este projeto gerou propriedade intelectual?</FormLabel>
                                            </div>
                                            <div className="col-6">
                                                <FormControl>
                                                    <RadioGroup row aria-labelledby="demo-radio-buttons-group-label"
                                                        {...register('hasIntellectualProperty', { onChange: (e) => setValue('hasIntellectualProperty', e.target.value === "true") })}
                                                        defaultValue={getValues('hasIntellectualProperty') ?? "false"}
                                                    >
                                                        {radioGrouplist.map((p, i) => (
                                                            <FormControlLabel key={i} value={p.val} control={<Radio />} label={p.label} disabled={isDisabledField} />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="mb-4">
                            <Accordion>
                                <div className="text-title">
                                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMore />}>
                                        <Typography component={'span'} variant={'body2'}>Natureza</Typography>
                                    </AccordionSummary>
                                </div>
                                <AccordionDetails>
                                    <Typography component={'span'} variant={'body2'}>
                                        <div className="row mb-1 mt-2">
                                            <div className='col-12'>
                                                <SelectCustom
                                                    label="Natureza"
                                                    field="nature"
                                                    control={control}
                                                    fieldErrors={errors.nature}
                                                    register={register}
                                                    defaultValue={getValues('nature')?.toString() ?? null}
                                                    list={naturelist}
                                                    isDisabled={isDisabledField}
                                                    haschange={() => { }}
                                                />
                                            </div>
                                        </div>
                                        {getValues('nature')?.toString() === naturelist[0].id ? (
                                            <div>
                                                <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>Inciso I</h5><br />
                                                <h6 className="mt-0">
                                                    Trabalho teórico ou experimental realizado de forma sistemática para adquirir novos conhecimentos, visando a atingir objetivo específico, descobrir novas aplicações ou obter ampla e precisa compreensão dos fundamentos subjacentes aos fenômenos e fatos observados, sem prévia definição para o aproveitamento prático dos resultados.
                                                </h6>
                                            </div>
                                        ) :
                                            getValues('nature')?.toString() === naturelist[1].id ? (
                                                <div>
                                                    <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>Inciso II</h5><br />
                                                    <h6 className="mt-0">
                                                        Trabalho sistemático utilizando o conhecimento adquirido na pesquisa ou experiência prática, para desenvolver novos materiais, produtos, dispositivos ou programas de computador, para implementar novos processos, sistemas ou serviços ou, então, para aperfeiçoar os já produzidos ou implantados, incorporando características inovadoras.
                                                    </h6>
                                                </div>
                                            )
                                                :
                                                getValues('nature')?.toString() === naturelist[2].id ? (
                                                    <div>
                                                        <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>Inciso III</h5><br />
                                                        <h6 className="mt-0">
                                                            Serviço científico e tecnológico de assessoria, consultoria, estudos, ensaios, metrologia, normalização, gestão tecnológica, fomento à invenção e inovação, gestão e controle da propriedade intelectual gerada dentro das atividades de pesquisa e desenvolvimento, bem como implantação e operação de incubadoras de base tecnológica em tecnologias da informação, desde que associadas a quaisquer das atividades previstas nos incisos I e II deste artigo.
                                                        </h6>
                                                    </div>
                                                )
                                                    :
                                                    getValues('nature')?.toString() === naturelist[3].id ? (
                                                        <div>
                                                            <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>Inciso IV-a</h5><br />
                                                            <h6 className="mt-0">
                                                                Formação ou capacitação profissional de níveis médio e superior: aperfeiçoamento e desenvolvimento de recursos humanos em tecnologias da informação.
                                                            </h6>
                                                        </div>
                                                    )
                                                        :
                                                        getValues('nature')?.toString() === naturelist[4].id ? (
                                                            <div>
                                                                <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>Inciso IV-b</h5><br />
                                                                <h6 className="mt-0">
                                                                    Formação ou capacitação profissional de níveis médio e superior: aperfeiçoamento e desenvolvimento de recursos humanos envolvidos nas atividades de que tratam os incisos de I a III deste artigo.
                                                                </h6>
                                                            </div>
                                                        )
                                                            :
                                                            getValues('nature')?.toString() === naturelist[5].id ? (
                                                                <div>
                                                                    <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>Inciso IV-c</h5><br />
                                                                    <h6 className="mt-0">
                                                                        Formação ou capacitação profissional de níveis médio e superior: c) em cursos de formação profissional, de nível superior e de pós-graduação, observado o disposto no inciso III do art. 27.
                                                                    </h6>
                                                                </div>
                                                            )
                                                                :
                                                                getValues('nature')?.toString() === naturelist[6].id ? (

                                                                    <div>
                                                                        <h5 className="mt-4" style={{ fontWeight: "bold", textAlign: "center" }}>1º</h5><br />
                                                                        <h6 className="mt-0">
                                                                            Intercâmbio: intercâmbio científico e tecnológico, internacional e inter-regional.
                                                                        </h6>
                                                                    </div>
                                                                )
                                                                    : null}
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="mb-4">
                            <Accordion>
                                <div className="text-title">
                                    <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" expandIcon={<ExpandMore />}>
                                        <Typography component={'span'} variant={'body2'}>Responsável pelo Projeto</Typography>
                                    </AccordionSummary>
                                </div>
                                <AccordionDetails>
                                    <Typography component={'span'} variant={'body2'}>
                                        <div className="row mb-1 mt-2">
                                            <div className='col-12 mb-3'>
                                                <TextFieldCustom
                                                    label="Nome"
                                                    field="name"
                                                    control={control}
                                                    fieldErrors={errors.name}
                                                    getValues={getValues}
                                                    isDisabled={isDisabledField}
                                                    register={register}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className='col-9'>
                                                <TextFieldCustom
                                                    label="CPF"
                                                    field="cpf"
                                                    control={control}
                                                    fieldErrors={errors.name}
                                                    getValues={getValues}
                                                    register={register}
                                                    typeMask={InputCpfMaskCustom}
                                                    isDisabled={isCpfDisabled || isDisabledField}
                                                />
                                            </div>
                                            <div className='col-3 mt-2'>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                disabled={isDisabledField}
                                                                checked={getValues('hasCpf') ?? false}
                                                                {...register('hasCpf', { onChange: () => hasCpfChange() })}
                                                            />}
                                                        label="Não tem CPF"
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className='col-9'>
                                                <TextFieldCustom
                                                    label="Telefone"
                                                    field="telephone"
                                                    control={control}
                                                    fieldErrors={errors.telephone}
                                                    getValues={getValues}
                                                    register={register}
                                                    typeMask={InputCellMaskCustom}
                                                    isDisabled={isDisabledField}
                                                    isMobile={isMobile}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <SelectCustom
                                                    label="Tipo de telefone"
                                                    field="phoneType"
                                                    control={control}
                                                    fieldErrors={errors.phoneType}
                                                    register={register}
                                                    defaultValue={getValues('phoneType')?.toString() ?? null}
                                                    list={phoneTypeList}
                                                    isDisabled={isDisabledField}
                                                    haschange={phoneTypeChange}
                                                />
                                            </div>
                                        </div>
                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="mb-4">
                            <AccordionCustom
                                title="Escopo do Projeto"
                                field="projectScope"
                                control={control}
                                fieldErrors={errors.projectScope}
                                getValues={getValues}
                                register={register}
                                isDisabled={isDisabledField}
                                isVisible="criterionCFrameworkAnalysis"

                            />
                        </div>
                        {isProjectLastYear
                            ?
                            <>
                                <div className="mb-4">
                                    <AccordionCustom
                                        title="Objetivo"
                                        field="objective"
                                        control={control}
                                        fieldErrors={errors.objective}
                                        register={register}
                                        getValues={getValues}
                                        isVisible="criterionCFrameworkAnalysis"
                                    />
                                </div>
                                <div className="mb-4">
                                    <AccordionCustom
                                        title="Descrição das atividades"
                                        field="descriptionActivities"
                                        control={control}
                                        fieldErrors={errors.descriptionActivities}
                                        register={register}
                                        getValues={getValues}
                                        isVisible="criterionCFrameworkAnalysis"
                                    />
                                </div>
                                <div className="mb-4">
                                    <AccordionCustom
                                        title="Resultado Obtidos"
                                        field="resultsObtained"
                                        control={control}
                                        fieldErrors={errors.resultsObtained}
                                        register={register}
                                        getValues={getValues}
                                        isVisible="criterionCFrameworkAnalysis"
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className="mb-4">
                                    <AccordionCustom
                                        title="O projeto teve desenvolvimento no Ano-Base?"
                                        field="developmentBaseYear"
                                        control={control}
                                        fieldErrors={errors.developmentBaseYear}
                                        getValues={getValues}
                                        register={register}
                                        isVisible="criterionCFrameworkAnalysis"
                                    />
                                </div>
                                <div className="mb-4">
                                    <AccordionCustom
                                        title="Quais etapas metodológicas estão previstas no projeto no ano?"
                                        field="stepsForeseenProject"
                                        control={control}
                                        fieldErrors={errors.stepsForeseenProject}
                                        getValues={getValues}
                                        register={register}
                                        isVisible="criterionCFrameworkAnalysis"
                                    />
                                </div>
                                <div className="mb-4">
                                    <AccordionCustom
                                        title="Qual problema técnico científico o projeto visa solucionar em seu desenvolvimento no ano?"
                                        field="scientificTechnicalProblem"
                                        control={control}
                                        fieldErrors={errors.scientificTechnicalProblem}
                                        getValues={getValues}
                                        register={register}
                                        isVisible="criterionCFrameworkAnalysis"
                                    />
                                </div>
                            </>
                        }
                        <div className="row mb-3">
                            {isButtonSave && <ButtonSubmitDefault type="submit" />}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}