import { useAuth } from "oidc-react";
import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { api, CommonHeaderProperties } from '../services/axios';
import { clearSession, getSession, setSession } from "../utils/storageSession";
import { RoleSessionName, UserSessionName } from "../Static/TokenNames";

interface AuthProps {
    children: ReactNode;
}

export interface AuthContextProps {
    user: UserProps;
    roles: string[];
    getRolesByUser : (tenantId : string) => Promise<void>;
}

interface UserProps {
    userName: string;
    email: string;
    lastName: string;
    roles: string[];
    errors: string[]
    statusCode: number;
}

export const AuthContext = createContext({} as AuthContextProps);

export function AuthProvider({ children }: AuthProps) {
    const { isLoading, userData } = useAuth();

    const [roles, setRoles] = useState<string[]>(() => {
        const role = getSession(RoleSessionName);

        if (role) {
            const roleParsed  = JSON.parse(role);
            return roleParsed;
        }

        return []
    });

    

    const [user, setUser] = useState<UserProps>(() => {
        const user = getSession(UserSessionName);

        if (user) {
            const userParsed  = JSON.parse(user);

            return userParsed;
        }

        if(userData != null){
            setSession(UserSessionName, JSON.stringify(userData));
            return userData;
        }

        return {} as UserProps;
    });

    async function getRolesByUser(tenantId : string){
        try{
            await api.get(`TenantsCompanies/GetUserRoles/${tenantId}`)
            .then(result => {
                const response = result.data;
                if(response.statusCode === 200){
                    setRoles(response.data.roles);
                    setSession(RoleSessionName, JSON.stringify(response.data.roles));
                }
            });
            
        }
        catch{

        }
        finally{

        }
    }
    
    const value = useMemo(
        () => ({ user, getRolesByUser }),
        [user]
    );

    return (
        <AuthContext.Provider value={{...value, roles}}>
            {children}
        </AuthContext.Provider>
    );
}