import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";
import { Product } from "../../../models/productModel";
import { api } from "../../../services/axios";
import { AiFillHome } from "react-icons/ai";
import { toast } from "react-toastify";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import useModal from "../../../hooks/useModal";
import { useCompany } from "../../../hooks/useCompany";
import { FillTable } from "../../../components/Shared/FillTable";
import { HiOutlineUpload, } from "react-icons/hi";
import { BsDownload, BsArrowRightCircle } from "react-icons/bs";
import { IoCodeSlash } from "react-icons/io5";
import { UploadModal } from "../../../components/Shared/UploadModal";
import UploadXMLInvoicingModal from "../../../components/Invoicing/UploadXMLInvoicingModal";
import { Button } from "@mui/material";
import './styles.css';
import { SetFileName } from "../../../utils/SetFileName";
import { Delete } from "@mui/icons-material";
import { DeleteInvoicingMassiveModal } from "../../../components/Invoicing/DeleteInvoicingMassiveModal";

interface ProductsPagination {
    items: Product[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export default function InvoicingModels() {

    const [fileInvoicing, setFile] = useState<Blob>();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [productsPagination, setProductsPagination] = useState<ProductsPagination>();
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const { company, updateHeaderValues, getObligationTotalValue } = useCompany();
    const { companyId } = useParams();
    const { isShowing, toggle } = useModal();
    const { isShowing: isShowingExcelModal, toggle: toggleExcelModal } = useModal();
    const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
    const navigation = useNavigate();

    const toggleOptions = () => {
        setShowOptions(!showOptions);
    }

    async function handleUploadInvoicing() {
        try {
            debugger
            setLoading(true);
            toggleExcelModal();

            const formData = new FormData();

            if (fileInvoicing != undefined) {
                formData.append('file', fileInvoicing);
            }

            await api.post(`invoicing/UploadExcel/${company.taxNumber}/${company.serviceId}/${company.year}/${companyId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((resp) => {
                const response = resp.data;

                if (response.statusCode == 200) {
                    updateHeaderValues();
                    getObligationTotalValue();
                    toast.success("Upload do Excel Concluído!");
                }
            });
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDownloadExcelModelBase() {
        try {
            setLoading(true);

            await api.get(`invoicing/download/${company.serviceId}`, {
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', SetFileName(company.title, company.year.toString(), "faturamento", "xlsx"));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getProducts() {
        try {
            setLoading(true);

            await api.get(`/product/GetAllEligible/companyId=${companyId}&page=1&take=7&word=""`).then((resp) => {
                const response = resp.data;

                const data: ProductsPagination = response.data;

                setProductsPagination(data);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function changePage(page: number) {
        try {
            setLoading(true);

            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`/product/GetAllEligible/companyId=${companyId}&page=${page}&take=7&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;
                setProductsPagination(response.data);
                setCurrentPage(page);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false)
        }
    }

    async function goToPage() {
        navigation(`/${company.serviceId}/Logs`);
    }

    async function screenAccess() {
        try {
            setLoading(true);

            await api.get(`Invoicing/ScreenAccessInvoicing/serviceId=${company.serviceId}`);
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        /*       screenAccess();*/
        getProducts();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>II.Faturamentos</span>
                    </div>
                </div>
                <div className='card-body divAbsolut'>
                    <div className="d-flex justify-content-end aling-items-center">
                        <Button variant="contained" className='btn  p-2 mb-4 text-center me-2 justify-content-around' onClick={handleDownloadExcelModelBase}>
                            Download Modelo
                            <BsDownload className='ms-2' size={20} />
                        </Button>
                        <Button variant="contained" className='btn  p-2 mb-4 text-center me-2 justify-content-around' onClick={toggleExcelModal}>
                            Upload Modelo
                            <HiOutlineUpload className='ms-2' size={20} />
                        </Button>
                        <Button variant="contained" className='btn  p-2 mb-4 text-center me-2 justify-content-around' onClick={() => { toggle(); toggleOptions() }}>
                            Upload de Arquivos
                            <HiOutlineUpload className='ms-2' size={20} />
                        </Button>
                        <Button variant="contained" className='btn  p-2 mb-4 text-center justify-content-around' onClick={goToPage}>
                            Relatório de Arquivos
                            <BsDownload className='ms-2' size={20} />
                        </Button>
                    </div>
                    <table className='table table-bordered table-striped table-hover mb-4'>
                        <tbody>
                            <tr>
                                <th scope="row" className="card-6">Itens não atrelados a modelos/produtos</th>
                                <td className="ms-2 cursor-pointer text-end">
                                    <Link to={`/${company.serviceId}/${company.companyId}/invoicingNotLinked`}>
                                        <BsArrowRightCircle className='cursor-pointer' />
                                    </Link>
                                </td>
                            </tr>
                            {productsPagination?.items.map(model => {
                                return (
                                    <>
                                        <tr key={model.id}>
                                            <th scope="row" className="card-6">{model.name}</th>
                                            <td className="ms-2 cursor-pointer text-end">
                                                <Link to={`/${company.serviceId}/${company.companyId}/invoicing/list/${model.id}/0`}>
                                                    <BsArrowRightCircle className='cursor-pointer ' />
                                                </Link>
                                            </td>
                                        </tr>
                                        {(model.tecnacProduct && model.tecnacProduct.status == 2) &&
                                            <tr key={model.tecnacProductId}>
                                                <th scope="row" className="card-6">{model.name} - TECNAC</th>
                                                <td className="ms-2 cursor-pointer text-end">
                                                    <Link to={`/${company.serviceId}/${company.companyId}/invoicing/list/${model.id}/1`}>
                                                        <BsArrowRightCircle className='cursor-pointer ' />
                                                    </Link>
                                                </td>
                                            </tr>
                                        }
                                    </>
                                );
                            })}
                            {productsPagination?.items && <FillTable columns={2} rows={productsPagination.items.length} />}
                        </tbody>
                    </table>

                    {productsPagination?.items &&
                        <TablePaginationFooter
                            totalItems={productsPagination?.total}
                            change={changePage}
                        />
                    }
                    {productsPagination?.items &&
                        <div className="w-25">
                            <Button color="info" variant="contained" onClick={toggleDeleteModal} endIcon={<Delete />} className='w-50 btn-delete-massive'>
                                Delete Massivo
                            </Button>
                        </div>
                    }
                </div>
            </div>
            {isShowing && <UploadXMLInvoicingModal setLoading={setLoading} title='Upload de Notas' isShowing={isShowing} hide={toggle} />}
            {isShowingExcelModal &&
                <UploadModal
                    action={handleUploadInvoicing}
                    icon={IoCodeSlash}
                    isShowing={isShowingExcelModal}
                    toggle={toggleExcelModal}
                    setFile={setFile}
                />
            }
            <DeleteInvoicingMassiveModal company={company} serviceId={company.serviceId} isShowing={isShowingDeleteModal} toggle={toggleDeleteModal} />
        </>
    );
}