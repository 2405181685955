import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../../components/Shared/Loader";
import { ComponentsTable } from "../ComponentsTable";
import { api } from "../../../services/axios";
import { useForm } from "react-hook-form";
import { createPath, useParams } from "react-router-dom";
import { v4 as uuidV4 } from 'uuid';
import { AiFillHome, AiOutlinePlus } from "react-icons/ai";
import useModal from "../../../hooks/useModal";
import { Collapse, Modal as ModalContainer } from 'react-bootstrap';
import { FaTrash } from "react-icons/fa";
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useCompany } from "../../../hooks/useCompany";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";

export interface ComponentsProps {
    id: string;
    name: string;
    origin: string;
    percentage: number;

}

interface ComponentProductProps {
    model?: ProductModelProps | null;
    productId: string | undefined;
    show: boolean;
    productStatus: number;
}

export default function ComponentProduct({ model, productId, show, productStatus }: ComponentProductProps) {
    const [loading, setLoading] = useState(false);
    const [newComponents, setNewCompoments] = useState<ComponentsProps[]>([]);
    const [savedComponents, setSavedCompoments] = useState<ComponentsProps[]>([]);
    const [componentsName, setComponentsName] = useState('');
    const [componentsOrigin, setComponentsOrigin] = useState('');
    const [componentsPercentage, setComponentsPercentage] = useState(0);
    const [percentageTotal, setPercentageTotal] = useState(0);
    const { isShowing: isShowingDeleteModal, toggle: toggleDeleteModal } = useModal();
    const { company } = useCompany();


    function isValidComponent(component: ComponentsProps): boolean {

        if (!component.name) {
            return false;
        }

        if (!component.percentage) {
            return false;
        }

        if (!component.origin) {
            return false;
        }

        if (Number.isNaN(component.percentage)) {
            toast.error(`Não é possível inserir o componente ${component.name} pois o valor da porcentagem não é válido`);
            return false
        }

        if (component.percentage > 100) {
            toast.error(`Não é possível inserir o componente ${component.name} pois o valor da porcentagem é maior que 100 %`);
            return false;
        }

        if ((percentageTotal + component.percentage) > 100) {
            toast.error(`Não é possível inserir o componente ${component.name} pois a soma das porcentagens ultrapassa 100 %`);
            return false;
        }


        if (newComponents.some(x => x.name == component.name && x.origin == component.origin)) {
            toast.error(`Não é possível inserir o componente ${component.name} pois ele já existe!`);
            return false;
        }

        if (savedComponents.some(x => x.name == component.name && x.origin == component.origin)) {
            toast.error(`Não é possível inserir o componente ${component.name} pois ele já existe!`);
            return false;
        }

        return true;
    }

    function addComponent(component: ComponentsProps) {
        if (isValidComponent(component)) {
            setNewCompoments(components => [...components, component]);
            setPercentageTotal(total => total + component.percentage);
        }
    }

    function addSavedComponetsRange(components: ComponentsProps[]) {
        components.forEach(element => {
            setSavedCompoments(components => [...components, element]);
        });
    }

    function removeComponent(id: string) {
        const componentesFiltered = newComponents.filter(component => {
            return component.id != id;
        });

        if (percentageTotal > 0) {
            const component = newComponents.find(component => {
                return component.id == id;
            });

            if (component) {
                setPercentageTotal(total => total - component?.percentage);
            }
        }

        toast.success('Componente removido com sucesso!');
        setNewCompoments(componentesFiltered);

    }

    function removeSavedComponentFromList(id: string) {
        const componentesFiltered = savedComponents.filter(component => {
            return component.id != id;
        });

        if (percentageTotal > 0) {
            const component = savedComponents.find(component => {
                return component.id == id;
            });

            if (component) {
                setPercentageTotal(total => total - component?.percentage);
            }

        }

        setSavedCompoments(componentesFiltered);

    }

    async function removeSavedComponent(id: string) {
        setLoading(true);
        try {
            const result = await api.delete(`component/Delete/${id}/${company.serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Componente deletado com sucesso!');
                removeSavedComponentFromList(id);

            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }

    }

    function removeAllNewComponents() {
        setNewCompoments([]);
        setPercentageTotal(0);
        toast.success('Componentes deletados com sucesso!');
        toggleDeleteModal();
    }

    async function removeAllSavedComponents() {
        setLoading(true);
        try {
            const result = await api.delete(`component/DeleteByProduct/${productId}/${company.serviceId}`);
            const response = await result.data

            if (response.statusCode === 200) {
                toast.success('Componentes deletados com sucesso!');
                setSavedCompoments([]);
                setPercentageTotal(0);
                toggleDeleteModal();
            }
        }
        catch (err: any) {

        }
        finally {
            setLoading(false);
        }
    }

    function handleAddLine() {

        if (componentsName && componentsOrigin && componentsPercentage) {
            const object = {
                id: uuidV4(),
                name: componentsName,
                origin: componentsOrigin,
                percentage: componentsPercentage
            }
            addComponent(object);
            setComponentsName('');
            setComponentsOrigin('');
            setComponentsPercentage(0);
        }
    }

    async function handleSaveData() {
        try {
            setLoading(true);

            const object = {
                components: newComponents,
                productId: productId,
                serviceId: company.serviceId,
            }

            const result = await api.post('component/Post', object);

            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Os dados foram salvos com sucesso!');
                addSavedComponetsRange(newComponents);
                setNewCompoments([]);
            }
        }
        catch (err: any) {
            toast.error(err);
        }
        finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        async function getComponents() {
            setLoading(true);
            try {
                const result = await api.get(`component/GetAllByProduct/${productId}`);
                const response = await result.data;

                if (response.statusCode === 200) {
                    const data: ComponentsProps[] = response.data;

                    let percentageSum = data.reduce((accumulator, object) => {
                        return accumulator + object.percentage;
                    }, 0);
                    setPercentageTotal(percentageSum);
                    setSavedCompoments(response.data);
                }
            }
            catch (err: any) {

            }
            finally {
                setLoading(false);
            }
        }

        getComponents();

    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className={`bg-light w-100 h-100 p-4 border ${show ? '' : 'd-none'}`}>
                <div>
                    <div>
                        <h4>Composição Média do Produto</h4>
                        <ComponentsTable
                            newComponents={newComponents}
                            savedComponents={savedComponents}
                            removeComponent={removeComponent}
                            removeAllNewComponents={removeAllNewComponents}
                            removeSavedComponent={removeSavedComponent}
                            removeAllSavedComponents={removeAllSavedComponents}
                            productStatus={productStatus}
                        />
                    </div>
                    <div className='mt-4'>
                        {productStatus == 0 &&
                            <>
                                <div className='row'>
                                    <div className='col'>
                                        <TextField
                                            type="text"
                                            className='form-control mb-3'
                                            label='Nome do Componente'
                                            onChange={(e) => setComponentsName(e.target.value)}
                                            value={componentsName}
                                        />
                                    </div>
                                    <div className='col '>
                                        <FormControl className='form-control w-100'>
                                            <InputLabel id="components-origin-label">Selecione a Origem</InputLabel>
                                            <Select
                                                label="Origem"
                                                labelId="components-origin-label"
                                                className=' '
                                                value={componentsOrigin}
                                                onChange={(e) => setComponentsOrigin(e.target.value)}
                                            >

                                                <MenuItem value={'Nacional'}>Nacional</MenuItem>
                                                <MenuItem value={'Estrangeira'}>Estrangeira</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className='col'>
                                        <TextField
                                            type="number"
                                            className='form-control'
                                            label='Porcentagem'
                                            placeholder='00%'
                                            inputProps={{ step: '0.01' }}
                                            onChange={(e) => setComponentsPercentage(parseFloat(e.target.value))}
                                            value={componentsPercentage}
                                        />
                                    </div>
                                </div>
                                <Button
                                    variant='contained'
                                    className='cursor-pointer btn btn-success mt-4'
                                    onClick={handleAddLine}
                                >
                                    Adicionar Linha
                                    <AiOutlinePlus className='ms-2' />
                                </Button>
                            </>
                        }
                        <div className='alert alert-dark d-flex align-items-center justify-content-evenly w-25 container'>
                            <h2 className="text-bold mt-2">Total:</h2>
                            <h2 className="mt-2">{percentageTotal}%</h2>
                        </div>
                        {productStatus == 0 &&
                            <>
                                <div className='row mt-4'>
                                    <div className='col-10'>
                                        <Button variant="contained" className=' btn-success w-100' type='button' onClick={handleSaveData} >Salvar Alterações</Button>
                                    </div>
                                    <div className='d-flex justify-content-end col-2 text-nowrap'>
                                        <Button variant="contained" className='btn btn-remove w-100' type='button' onClick={toggleDeleteModal} >
                                            <FaTrash />
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
            <ModalContainer show={isShowingDeleteModal} onHide={toggleDeleteModal}>
                <ModalContainer.Header closeButton>
                    <ModalContainer.Title>Exclusão massiva</ModalContainer.Title>
                </ModalContainer.Header>
                <ModalContainer.Body>
                    <div className=' d-flex align-items-center justify-content-center'>
                        <button
                            className='btn btn-success me-2'
                            onClick={removeAllNewComponents}
                        >
                            Componentes novos
                        </button>
                        <button
                            className='btn btn-success'
                            onClick={removeAllSavedComponents}
                        >
                            Componentes salvos
                        </button>
                    </div>
                </ModalContainer.Body>
            </ModalContainer >

        </>
    );


}