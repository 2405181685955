import { Col, Row } from "react-bootstrap";
import { AiFillHome } from "react-icons/ai";
import { FaFolderOpen } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useCompany } from "../../hooks/useCompany";

import { CustomCard } from "../../components/Shared/CardCompany";
import { useCan } from "../../hooks/useCan";

interface contentCards {
    permission: boolean,
    path: string,
    title: string
}

export function FileManager() {
    const { company } = useCompany();

    const objsCard: contentCards[] = [
        {
            permission: useCan({ rolesCan: ["Administrator", "Master", "Technician"] }),
            path: "fileManager/files/0",
            title: "Documentação Técnica"
        },
        {
            permission: useCan({ rolesCan: ["Administrator"] }),
            path: "fileManager/files/1",
            title: "Documentação de Recursos Humanos"
        },
        {
            permission: useCan({ rolesCan: ["Administrator", "Financial", "Master"] }),
            path: "fileManager/files/2",
            title: "Documentação Financeira"
        },
        {
            permission: useCan({ rolesCan: ["Administrator", "Master"] }),
            path: "fileManager/files/3",
            title: "Documentação Master"
        }
    ]

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item'>Gerenciador de Documentos</span>
                </div>
            </div>
            <div className='card-body'>
                <Row className='w-100'>

                    {objsCard.map((card) => {
                        return (
                            card.permission && <CustomCard path={card.path} title={card.title}></CustomCard> 
                        );
                    })}
                </Row>
            </div>
        </div>
    )
}