import { useEffect, useState } from "react";
import { InvoicingModel } from "../../../models/invoicingModel";
import { api } from "../../../services/axios";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import { formatDate, formatDateMonth } from "../../../utils/formatDate";
import { useCompany } from "../../../hooks/useCompany";
import { AiFillHome } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../components/Shared/Loader";
import { FillTable } from "../../../components/Shared/FillTable";
import PaginationTabHeader from "../../../components/Shared/PaginationTabHeader";
import InvoicingAction from "../../../components/Invoicing/InvoicingAction";
import { IJustificationProps } from "../InvoicingProductExit";
import { GrUpdate } from 'react-icons/gr';
import { Button } from "@mui/material";

interface InvoicingPagination {
    items: InvoicingModel[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}

export default function InvoicingNotLinked() {

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [key, setKey] = useState<number>(0);
    const [type, setType] = useState<number>(0);
    const [searchText, setSearchText] = useState("");
    const [invoicingPagination, setInvoicingPagination] = useState<InvoicingPagination>();
    const [selectedValue, setSelectedValue] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [checkedInconsistencies, setCheckedInconsistencies] = useState<string[]>([]);
    const [checkAllInconsistencies, setCheckAllInconsistencies] = useState(false);
    const [justification, setJustification] = useState<IJustificationProps>();
    const { company } = useCompany();
    const { serviceId } = useParams();

    async function accessScreen() {
        try {
            setLoading(true);

            await api.get(`invoicing/ScreenAccessNotLinked/serviceId=${company.serviceId}`);
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getInvoicing(page?: number) {
        try {
            setLoading(true);
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;

            await api.get(`invoicing/GetAllNotLinked/serviceId=${serviceId}&type=${type}&page=${page ?? 1}&take=7&word=${encodeURIComponent(text)}`).then((resp) => {
                const response = resp.data;
                setInvoicingPagination(response.data);
                setCurrentPage(page ?? 1);
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteInvoicing(id: string) {
        setLoading(true);
        try {

            await api.delete('invoicing/delete', {
                data: {
                    id: id,
                    serviceId: company.serviceId
                }
            }).then(resp => {
                const response = resp.data;

                if (response.statusCode === 200) {
                    toast.success('Faturamento deletado com sucesso!');

                    let invoicingFilter = invoicingPagination?.items.filter(model => model.id != id);

                    const filterInvoicing = {
                        items: invoicingFilter,
                        currentPage: invoicingPagination?.currentPage,
                        take: invoicingPagination?.take,
                        total: invoicingPagination?.total,
                        word: invoicingPagination?.word
                    } as InvoicingPagination;

                    setInvoicingPagination(filterInvoicing);
                }
            });
        }
        catch (error: any) {
            toast.error(error);
        }
        finally {
            setLoading(false);
        }
    }

    function handleSelection(inconsistencyId: number) {
        setSelectedValue(inconsistencyId);
    }

    function handleCheckInconsistency(checkedInconsistency: string) {
        if (checkedInconsistencies.some(e => e === checkedInconsistency)) {
            const filteredList = checkedInconsistencies.filter(item => {
                return item != checkedInconsistency;
            });

            setCheckedInconsistencies(filteredList);
        }
        else {
            setCheckedInconsistencies(ant => [...ant, checkedInconsistency]);
        }
    }

    async function getJustificationByInvoicingId(invoicingId: string) {
        try {
            setLoading(true);

            await api.get(`invoicing/GetJustificationByInvoicingId/invoicingId=${invoicingId}&inconsistencyType=${selectedValue}`).then((resp) => {
                if (resp.status == 200) {
                    setJustification(resp.data.data);
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function updateAllNotLinked() {
        try {
            setLoading(true);

            await api.put("/Invoicing/UpdateAllNotLinked", { companyId: company.companyId, serviceId: company.serviceId, CnpjMatrix: company.cnpj, InvoiceType: type }).then(resp => {
                if (resp.data.statusCode == 200) {
                    toast.success("Faturamentos atualizados!");
                    getInvoicing();
                }
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getInvoicing();
    }, [type]);

    useEffect(() => {
        accessScreen();
    }, []);

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <Link to={`/${company.serviceId}/${company.companyId}/invoicing`}><span className=''>II.Faturamentos</span></Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item-actual'>Itens não Faturados</span>
                    </div>
                </div>
                <div className='card-body bg-light w-100 h-100 p-4 border'>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <PaginationTabHeader setPag={setType} pag={type} setKey={setKey} keyDefault={key} tabs={["Vendas", "Devoluções"]} />
                        <div className="d-flex justify-content-end aling-items-center">
                            <Button variant="contained" className='btn  p-2 mr-2 mb-4 text-center me-2  justify-content-around' onClick={updateAllNotLinked}>
                                Atualizar Vínculos  <GrUpdate className="mb-1 mx-2 white-stroke" size={20} />
                            </Button>
                        </div>
                    </div>
                    <table key={key} className='table table-bordered table-striped table-hover mb-4'>
                        <thead>
                            <tr>
                                <th scope="col">Nº da Nota</th>
                                <th scope="col">NCM</th>
                                <th scope="col">Descrição do Item da Nota Fiscal</th>
                                <th scope="col">Mês de Emissão</th>
                                <th scope="col">Data de Emissão</th>
                                <th scope="col">Valor Total + Impostos</th>
                                <th scope="col">Valor de base de calculo</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoicingPagination?.items.map(model => {
                                return (
                                    <tr key={model.id}>
                                        <th scope="row">{model.invoiceNum}</th>
                                        <td>{model.itemNCM}</td>
                                        <td>{model.itemName}</td>
                                        <td>{model.invoiceDate != null ? formatDateMonth(new Date(model.invoiceDate)) : ""}</td>
                                        <td>{model.invoiceDate != null ? formatDate(new Date(model.invoiceDate)) : ""}</td>
                                        <td>R${((Number(model.itemUniValue.replace(",", ".")) * Number(model.itemQuantity)) + Number(model.itemIPI.replace(",", ".")) + Number(model.itemICMSSTDIFAL.replace(",", "."))).toFixed(2).toString().replace(".", ",")}</td>
                                        <td>R${(Number(model.itemUniValue.replace(",", ".")) * Number(model.itemQuantity)).toFixed(2).toString().replace(".", ",")}</td>
                                        <InvoicingAction
                                            selectedValue={selectedValue}
                                            isVisible={isVisible}
                                            id={model.id}
                                            action={() => handleDeleteInvoicing(model.id)}
                                            checkAllInconsistencies={checkAllInconsistencies}
                                            checkedInconsistencies={checkedInconsistencies}
                                            handleCheckInconsistency={handleCheckInconsistency}
                                            alreadyJustificated={model.alreadyJustificated ?? false}
                                            getJustificationByInvoicingId={getJustificationByInvoicingId}
                                            isLinked={false}
                                        />
                                    </tr>
                                );
                            })}
                            {invoicingPagination?.items && <FillTable columns={8} rows={invoicingPagination?.items.length} />}
                        </tbody>
                    </table>
                    {invoicingPagination?.items &&
                        <TablePaginationFooter
                            totalItems={invoicingPagination?.total}
                            change={getInvoicing}
                        />}
                </div>
            </div>
        </>
    );

}