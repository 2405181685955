import { TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FieldError, UseFormGetValues, UseFormRegister } from 'react-hook-form';

export interface ITypographyCustomProps {
    label: string,
    field: string,
    register: UseFormRegister<any>,
    getValues: UseFormGetValues<any>,
    fieldErrors?: FieldError | undefined,
}
 
export const TypographyCustom: React.FC<ITypographyCustomProps> = ({ label, field, register, getValues, fieldErrors }) => {
  const [charCount, setCharCount] = useState(0);
  const maxCaracteres = 32000;
  const isLimit = charCount > maxCaracteres;
 
  useEffect(() => {
    const initialValue = getValues(field) || '';
    setCharCount(initialValue.length);
  }, [getValues, field]);
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const texto = event.target.value;
    setCharCount(texto.length);
  };
 
  return (
    <Typography>
      <div className="row mb-1 mt-2">
        <div className="col-12">
          <TextField
            label={label}
            variant="outlined"
            className="form-control h-100"
            multiline
            rows={5}
            {...register(field)}
            defaultValue={getValues(field)}
            error={isLimit || !!fieldErrors}  
            helperText={isLimit ? "Limite de caracteres acima do valor máximo! Por Favor Apague os caracteres que ultrapassaram o valor máximo" : fieldErrors?.message}  
            onChange={handleInputChange}
          />
        </div>
        <p>{`${charCount}/${maxCaracteres}`}</p>
      </div>
    </Typography>
  );
};