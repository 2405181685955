import { GoPencil } from "react-icons/go";
import { BiTrashAlt } from "react-icons/bi";
import { BsCoin } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import { MdOutlineLaptop } from 'react-icons/md';
// import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import EconomicDataModal from "../EconomicDataModal";
import { useCan } from "../../../hooks/useCan";
//import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface ProductActionProps {
    id: string;
    action: any;
    canDelete: boolean;
    productStatus: number | null;
}

export default function ProductAction({ id, action, canDelete, productStatus }: ProductActionProps) {
    const { isShowing, toggle } = useModal();
    const { isShowing: isShowingEconomic, toggle: toogleEconomic } = useModal();
    const { company } = useCompany();
    const permissionfinancial = useCan({ rolesCan: ["Financial"] });

    return (
        <>
            <td className="col">
                {!permissionfinancial ?
                    <div className="d-flex justify-content-between align-items-center">

                        <Link title="Modelos cadastrados" to={`/${company.serviceId}/products/${id}/models/`}>
                            <MdOutlineLaptop className='ms-2 cursor-pointer' size={22} />
                        </Link>
                        {productStatus == 0 ?
                            <Link title="Dados econômicos e composição média" to={`/${company.serviceId}/products/economic/${id}`} state={{ productStatus: productStatus }} >
                                <BsCoin className='ms-2 cursor-pointer' size={20} />
                            </Link>
                            :
                            <Link title="Dados econômicos e composição média" to={`/${company.serviceId}/products/economic/${id}`} state={{ productStatus: productStatus }}>
                                <BsCoin className='ms-2 cursor-pointer' size={20} />
                            </Link>
                        }

                        <Link title="Consultar/Editar" to={`/${company.serviceId}/products/update/${id}`}><GoPencil className='ms-2 cursor-pointer' size={20} /></Link>
                        {canDelete ? <BiTrashAlt title="Excluir" className='ms-2 cursor-pointer' size={20} onClick={toggle} /> : <div className="ms-2 div-trash-size"></div>}

                    </div>
                    :
                    <div className="d-flex justify-content-between align-items-center">

                        <Link title="Modelos cadastrados" to={`/${company.serviceId}/products/${id}/models/`}>
                            <MdOutlineLaptop className='ms-2 cursor-pointer' size={22} />
                        </Link>


                    </div>
                }
            </td>
            {!permissionfinancial && (
                <DeleteModal title='Excluir Produto' text='Tem certeza que deseja excluir este produto?' isShowing={isShowing} hide={toggle} action={action} />
            )}
            {isShowingEconomic && <EconomicDataModal productId={id} hide={toogleEconomic} isShowing={isShowingEconomic} />}
        </>
    )
}