import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../components/Shared/Loader";
import { Product, ProductStatus } from "../../../models/productModel";
import { api } from "../../../services/axios";
import { AiFillHome } from "react-icons/ai";
import { toast } from "react-toastify";
import { TablePaginationFooter } from "../../../components/Shared/Pagination";
import { useForm } from "react-hook-form";
import { Col, InputGroup, Row } from "react-bootstrap";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductAction from "../../../components/Products/ProductActions";
import { FaTrash } from "react-icons/fa";
import DeleteModal from "../../../components/Shared/DeleteModal";
import useModal from "../../../hooks/useModal";
import { useCompany } from "../../../hooks/useCompany";
import ProductTecnacAction from "../../../components/Products/ProductTecnacActions";
import { string } from "yup/lib/locale";
import { FillTable } from "../../../components/Shared/FillTable";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import "./style.css"
import { useCan } from "../../../hooks/useCan";
interface ProductsPagination {
    items: Product[];
    total: number;
    currentPage: number;
    word: string;
    take: number;
}


interface SearchProjectProps {
    search: string;
}

export default function Products() {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [productsPagination, setProductsPagination] = useState<ProductsPagination>();
    const { isShowing, toggle } = useModal();
    const { company, updateHeaderValues } = useCompany();
    const { companyId } = useParams();

    const permissionFinancial = useCan({ rolesCan: ["Financial"] });
    const permissionMaster = useCan({ rolesCan: ["Master"] });
    const permissionTechnician = useCan({ rolesCan: ["Technician"] });

    var hasPermition = false

    if (permissionFinancial || permissionMaster) {
        hasPermition = true
    }

    const { register, handleSubmit, control, formState: { errors } } = useForm<SearchProjectProps>();

    async function getProducts() {
        setLoading(true);
        const result = await api.get(`/product/getAll/serviceId=${company.serviceId}&companyId=${companyId}&page=1&take=7&word=""`);
        const response = await result.data;

        const data: ProductsPagination = response.data;

        setProductsPagination(data);
        setLoading(false);
    }

    useEffect(() => {
        getProducts();
    }, []);

    async function search(data: SearchProjectProps) {
        setLoading(true);
        setProductsPagination(undefined);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            const result = await api.get(`/product/getAll/serviceId=${company.serviceId}&companyId=${companyId}&page=1&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;
            setProductsPagination(response.data);

        }
        catch (error: any) {
            console.log('ERROR', error);
        }
        finally {
            setLoading(false)
        }
    }

    async function changePage(page: number) {
        setLoading(true);
        try {
            let text = searchText == null || searchText == "" ? "\"\"" : searchText;
            const result = await api.get(`/product/getAll/serviceId=${company.serviceId}&companyId=${companyId}&page=${page}&take=7&word=${encodeURIComponent(text)}`);
            const response = await result.data;
            setProductsPagination(response.data);
            setCurrentPage(page);

        }
        catch (error: any) {
            console.log('CHANGE PAGE', error);
        }
        finally {
            setLoading(false)
        }
    }

    async function handleDeleteProduct(id: string) {

        setLoading(true);
        try {

            const result = await api.delete('product/delete', {
                data: {
                    id: id,
                    serviceId: company.serviceId
                }
            });
            const response = await result.data;
            const data = response.data;

            if (response.statusCode === 200) {
                toast.success('Produto deletado com sucesso!');

                let productsFilter = productsPagination?.items.filter(product => product.id != id);

                const filterProducts = {
                    items: productsFilter,
                    currentPage: productsPagination?.currentPage,
                    take: productsPagination?.take,
                    total: productsPagination?.total,
                    word: productsPagination?.word
                } as ProductsPagination;

                setProductsPagination(filterProducts);
                await updateHeaderValues();
            }
        }
        catch (Error: any) {
            console.log('Handle Delete Product Error', Error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteProductTecnac(tecnacId: string, productId: string) {
        setLoading(true);
        try {

            const result = await api.delete('productTecnac/delete', {
                data: {
                    productId,
                    tecnacId,
                    serviceId: company.serviceId
                }
            });
            const response = await result.data;
            const data = response.data;

            if (response.statusCode === 200) {
                toast.success('Produto Tecnac deletado com sucesso!');

                let productsFilter = productsPagination?.items
                    .map(product => {
                        if (product.id == productId) {
                            product.tecnacProductId = null
                        }

                        return product;
                    });

                const filterProducts = {
                    items: productsFilter,
                    currentPage: productsPagination?.currentPage,
                    take: productsPagination?.take,
                    total: productsPagination?.total,
                    word: productsPagination?.word
                } as ProductsPagination;

                setProductsPagination(filterProducts);
            }

        }
        catch (Error: any) {
            console.log('Handle Delete Product Error', Error);
        }
        finally {
            setLoading(false);
        }
    }

    async function handleDeleteAllProducts() {
        setLoading(true);
        try {
            const result = await api.delete(`product/deleteall/${company.serviceId}`);
            const response = await result.data;

            if (response.statusCode === 200) {
                toast.success('Todos os produtos foram deletados com sucesso!');
                setProductsPagination({} as ProductsPagination);
                await updateHeaderValues();
            }
        }
        catch (error: any) {
            console.log('DELETE ALL ERROR', error);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <div className='card '>
                <div className='card-header'>
                    <div className='breadCrumb'>
                        <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                            <AiFillHome className='breadCrumb-home' />
                        </Link>
                        <span className='breadCrumb-separator'>{' > '}</span>
                        <span className='breadCrumb-item'>I.Produtos {'>'} I.1 Produtos e Modelos</span>
                    </div>
                </div>
                <div className='card-body'>
                    <Row className='w-100 p-0 m-0'>
                        <Col xs={12} className='p-0 divforms d-flex align-items-center justify-content-end'>
                            <form className="w-25" onSubmit={handleSubmit(search)}>
                                <InputGroup className="mb-2 ">
                                    <input className='form-control' {...register("search")} placeholder="Pesquisar..." aria-label="Pesquisar" aria-describedby="basic-addon2" onChange={(e: any) => setSearchText(e.target.value)} />
                                    <InputGroup.Text id="basic-addon2">
                                        <button type='submit' className='p-0 m-0 border-0 bg-transparent'>
                                            <FontAwesomeIcon icon={faMagnifyingGlass} className="cursor-pointer" />
                                        </button>
                                    </InputGroup.Text>
                                </InputGroup>
                                <p className='error-message'>{errors.search?.message}</p>
                            </form>
                        </Col>
                    </Row>
                    <Table sx={{ minWidth: 650 }} className="table table-bordered table-striped table-hover mb-4 black">
                        <TableHead>
                            <TableRow>
                                <TableCell className="col-5">Nome do Produto</TableCell>
                                <TableCell className="col-2">NCM</TableCell>
                                <TableCell className="col-2">Portaria de Habilitação</TableCell>
                                <TableCell className="col-2">Portaria PPB</TableCell>
                                <TableCell className="col-2">Status</TableCell>
                                <TableCell className="col">Ações</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {productsPagination?.items.map((product) => {
                                return (
                                    <>
                                        <TableRow key={product.id}>
                                            {!permissionFinancial ?
                                                <TableCell component="th" scope="row">
                                                    {product.name}
                                                </TableCell> :
                                                <TableCell component="th" scope="row">
                                                    <Link title="Consultar" className="text-decoration-none" to={`/${company.serviceId}/products/update/${product.id}`}>{product.name}</Link>

                                                </TableCell>}
                                            <TableCell>{product.ncm}</TableCell>
                                            <TableCell>{product.ordinanceNumber}</TableCell>
                                            <TableCell>{product.ppbOrdinance}</TableCell>
                                            <TableCell>
                                                {product.status != null ? ProductStatus[product.status] : ''}
                                            </TableCell>
                                            <ProductAction
                                                canDelete={product.status == 2 ? false : true}
                                                id={product.id}
                                                action={() => handleDeleteProduct(product.id)}
                                                productStatus={product.status}
                                            />

                                        </TableRow>
                                        {product.tecnacProductId && (
                                            <TableRow key={`${product.id}-TECNAC`}>
                                                {!permissionFinancial ?
                                                    <TableCell component="th" scope="row">
                                                        {product.name} - TECNAC
                                                    </TableCell> :
                                                    <TableCell component="th" scope="row">
                                                        <Link title="Consultar" className="text-decoration-none" to={`/${company.serviceId}/products/tecnac/${product.id}`}>{product.name} - TECNAC</Link>
                                                    </TableCell>
                                                }
                                                <TableCell>{product.ncm}</TableCell>
                                                <TableCell>{product.tecnacProduct?.number}</TableCell>
                                                <TableCell>{product.ppbOrdinance}</TableCell>
                                                <TableCell>
                                                    {product.tecnacProduct != null &&
                                                        product.tecnacProduct?.status != null
                                                        ? ProductStatus[product.tecnacProduct?.status]
                                                        : ''}
                                                </TableCell>
                                                <ProductTecnacAction
                                                    productId={product.id}
                                                    tecnacId={product.tecnacProductId}
                                                    canDelete={product.tecnacProduct?.status == 2 ? false : true}
                                                    productStatus={product.tecnacProduct?.status}
                                                    action={() =>
                                                        handleDeleteProductTecnac(
                                                            product.tecnacProductId != null
                                                                ? product.tecnacProductId
                                                                : '',
                                                            product.id
                                                        )
                                                    }
                                                />
                                            </TableRow>
                                        )}
                                    </>
                                )
                            })}
                            {productsPagination?.items && (
                                <FillTable columns={6} rows={productsPagination?.items.length} />
                            )}
                        </TableBody>
                    </Table>
                    {productsPagination?.items &&
                        <TablePaginationFooter
                            totalItems={productsPagination?.total}
                            change={changePage}
                        />}
                    {!permissionFinancial ?
                        <div className='d-flex justify-content-center align-items-center mb-3'>
                            <Button variant="contained" className='rounded forms-control'><Link to={`/${company.serviceId}/products/create`} className='text-white'>Inserir Produto</Link></Button>
                        </div> :
                        <div className='d-flex justify-content-center align-items-center mb-3'>
                        </div>
                    }
                </div>
            </div>
            <DeleteModal title='Excluir Todos os Produtos' text='Tem certeza que deseja excluir todos os produtos cadastrados?' isShowing={isShowing} hide={toggle} action={handleDeleteAllProducts} />
        </>
    );
}