import { FaTrash } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useCompany } from "../../../hooks/useCompany";
import useModal from "../../../hooks/useModal";
import DeleteModal from "../../Shared/DeleteModal";
import { toast } from "react-toastify";
import { api } from "../../../services/axios";
import { maskCNPJ } from "../../../utils/cnpjUtils";
import {
    formatCurrency,
    formatCurrencyWithSign,
} from "../../../utils/formatCurrency";
import { useEffect, useState } from "react";

interface CreditCardProps {
    id: string;
    countings: string;
    creditValue: number;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    cnpj: string | undefined;
    validated: boolean;
    hasFile: boolean;
    getCredits(): Promise<void>;
    haspathCreditReceiptFilePath: string;
    haspathCreditFilePath: string;
    hasCreditApplicationDate: string | undefined;
}

const countingsLabel = ["Anual", "1º", "2º", "3º", "4º"];

const statusCodes = ["Crédito em geração", "Crédito gerado e enviado"];

export function CreditCard({
    countings,
    id,
    setLoading,
    cnpj,
    validated,
    getCredits,
    creditValue,
    hasCreditApplicationDate,
    haspathCreditFilePath,
    haspathCreditReceiptFilePath,
}: CreditCardProps) {
    const { serviceId } = useParams();
    //Faturamento incentivado: valores a serem somados
    const [investValueOthersRegions, setInvestValueOthersRegions] =
        useState<number>(0);
    const [investValueOthersRegionsTecNac, setInvestValueOthersRegionsTecNac] =
        useState<number>(0);
    const [investValueSudamSudeneCO, setInvestValueSudamSudeneCO] =
        useState<number>(0);
    const [investValueSudamSudeneCOTecNac, setInvestValueSudamSudeneCOTecNac] =
        useState<number>(0);
    const [invest, setInvest] = useState<number>();
    //Investimentos: valores a serem somados
    const [invoicing, setInvoicing] = useState<number>();

    const navigate = useNavigate();
    const { company } = useCompany();
    const countingsArray = countings.split(",");
    const { isShowing, toggle } = useModal();

    function subtitle(
        validated: boolean,
        hasCreditApplicationDate: any,
        haspathCreditFilePath: any,
        haspathCreditReceiptFilePath: any
    ) {
        if (
            validated &&
            hasCreditApplicationDate != undefined &&
            haspathCreditFilePath != null &&
            haspathCreditReceiptFilePath != null
        ) {
            return 1;
        } else {
            return 0;
        }
    }

    async function deleteCredit() {
        try {
            setLoading(true);

            await api
                .delete("/Credit/DeleteCredit", {
                    data: { creditId: id, serviceId: company.serviceId },
                })
                .then(() => {
                    toast.success("Crédito deletado com sucesso!");
                    getCredits();
                });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getInvestValue() {
        try {
            setLoading(true);

            await api.get(`/investValue/GetByCredit/${id}`).then((resp) => {
                const data = resp.data.data;

                const sumInvest =
                    data.investValueOthersRegions +
                    data.investValueOthersRegionsTecNac +
                    data.investValueSudamSudeneCO +
                    data.investValueSudamSudeneCOTecNac;
                setInvest(sumInvest);
            });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    async function getCardValues() {
        try {
            setLoading(true);

            await api
                .get(`/investValue/getConsolidation/${company.serviceId}/${id}`)
                .then((resp) => {
                    const data = resp.data.data;

                    const sumInvoicing =
                        data.othersRegionsConsolidation.invoicingConsolidation +
                        data.othersRegionsTecNacConsolidation.invoicingConsolidation +
                        data.sudamSudeneCOTecNacConsolidation.invoicingConsolidation +
                        data.sudamSudeneCOConsolidation.invoicingConsolidation;

                    setInvoicing(sumInvoicing);
                });
        } catch (error: any) {
            toast.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getCardValues();
        getInvestValue();
    }, [id]);

    return (
        <>
            <DeleteModal
                hide={toggle}
                isShowing={isShowing}
                text={`Você tem certeza que deseja excluir o crédito financeiro do período ${countingsArray.map(
                    (x, i) =>
                        countingsLabel[parseInt(x)] +
                        (i < countingsArray.length - 1 ? ", " : "")
                )} ${countingsArray?.length >= 1 && countingsArray[0] != "0" && "Trimestre"
                    } e CNPJ ${maskCNPJ(cnpj ?? "")}?`}
                title="Deletar Crédito Financeiro"
                action={deleteCredit}
            />
            <div
                onClick={() =>
                    navigate(`/${company.serviceId}/credit/tabs/${id}/${countings}`)
                }
            >
                <div
                    className={`d-flex align-items-center justify-content-between mb-0 ${validated && "mt-1"
                        }`}
                >
                    <strong>
                        Crédito{" "}
                        {countingsArray.map((x, index) => (
                            <span key={index}>{countingsLabel[parseInt(x)]} </span>
                        ))}
                        {countingsArray?.length >= 1 && countingsArray[0] != "0"
                            ? " Trimestre"
                            : ""}
                    </strong>
                    {!validated && (
                        <button
                            type="button"
                            className="btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggle();
                            }}
                        >
                            <FaTrash className="gray mb-1" />
                        </button>
                    )}
                </div>
                <div className={`mb-3 ${!validated && "margin-negative"}`}>
                    <span className="text-bold font-small">
                        CNPJ {maskCNPJ(cnpj ?? "")}
                    </span>
                </div>
                <div className="mb-2">
                    <div>
                        <small>
                            <strong>Faturamento incentivado</strong>
                        </small>
                        <p>{formatCurrency((invoicing ?? 0).toFixed(2))}</p>
                    </div>
                    <div className="w-75 border mb-2"></div>
                    <div>
                        <small>
                            <strong>Investimento</strong>
                        </small>
                        <p>{formatCurrency((invest ?? 0).toFixed(2))}</p>
                    </div>
                    <h4 className="text-end text-bold">
                        {formatCurrencyWithSign(creditValue.toString())}
                    </h4>
                </div>
                <h5 className="text-center">
                    <strong>
                        {
                            statusCodes[
                            subtitle(
                                validated,
                                hasCreditApplicationDate,
                                haspathCreditFilePath,
                                haspathCreditReceiptFilePath
                            )
                            ]
                        }
                    </strong>
                </h5>
            </div>
        </>
    );
}
