import { useEffect, useState } from "react";
import { AiFillHome } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import PaginationTabHeader from "../../../components/Shared/PaginationTabHeader";
import { useCompany } from "../../../hooks/useCompany";
import { api } from "../../../services/axios";
import { ModelCharacterization } from "../ModelCharacterization";
import { ModelComponents } from "../ModelComponents";
import { ModelEconomicDataFormsProps, ProductionAndObservation } from "../ProductionAndObservation";
import { HabilitationControl } from "../HabilitationControl";
import { ProductProps } from "../../../interfaces/Product/IProduct";
import { ProductModelProps } from "../../../interfaces/ProductModel/IProductModel";


export function ModelTabs() {
    const [pag, setPag] = useState(0);
    const [key, setKey] = useState(0);
    const [product, setProduct] = useState<ProductProps>();
    const [model, setModel] = useState<ProductModelProps>();
    const [modelEconomicData, setModelEconomicData] = useState<ModelEconomicDataFormsProps>();
    const { company } = useCompany();

    const { modelId, isTecnac, tab } = useParams();

    async function getModel() {
        const result = await api.get(`productModel/getById/${modelId}`);
        const response = await result.data;

        if (response.statusCode === 200) {
            console.log(response.data);
            setModel(response.data);
        }
    }


    useEffect(() => {

        if (tab)
            setPag(1);

        async function getProduct() {
            const result = await api.get(`product/getByModel/${modelId}`);
            const response = await result.data;

            if (response.statusCode === 200) {
                setProduct(response.data);
            }
        }

       
        // async function getModelEconomicData() {
        //     const result = await api.get(`modelEconomicData/getByModel/${modelId}`);
        //     const response = await result.data;

        //     if (response.statusCode === 200) {
        //         setModelEconomicData(response.data);
        //     }
        // }

        // getModelEconomicData();
        getModel();
        getProduct();
    }, []);

    function getTabs() {
        switch (pag) {
            case 0:
                return (model ? <ModelCharacterization model={model} product={product} /> : '');
            case 1:
                return (<ProductionAndObservation />)
            case 2:
                return (model ? <ModelComponents model={model} /> : '')
            case 3:
                return ( model ?  <HabilitationControl productModel={model} getModel={getModel} /> : '');
            default:
                return <></>;
        }
    }

    return (
        <div className='card'>
            <div className='card-header'>
                <div className='breadCrumb'>
                    <Link to={`/${company.serviceId}/home`} className='breadCrumb-home d-flex align-center'>
                        <AiFillHome className='breadCrumb-home' />
                    </Link>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item-actual'>
                        <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                            I.Produtos
                        </Link>
                    </span>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item-actual'>
                        <Link to={`/${company.serviceId}/${company.companyId}/products`}>
                            I.1 Produtos e Modelos
                        </Link>
                    </span>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item-actual'>
                        <Link to={product?.tecnacProductId != null && isTecnac == 'true' ? `/${company.serviceId}/products/${product.id}/${product.tecnacProductId}/models/` : `/${company.serviceId}/products/${product?.id}/models/`}>
                            Modelos de Produtos
                        </Link>
                    </span>
                    <span className='breadCrumb-separator'>{' > '}</span>
                    <span className='breadCrumb-item'>Modelo {model?.name} - Dados Gerais</span>
                </div>
            </div>
            <div className='card-body'>
                <div>
                    <PaginationTabHeader
                        setPag={setPag}
                        pag={pag}
                        setKey={setKey}
                        keyDefault={key}
                        tabs={
                            model?.isCreatedByProduct == false ?
                                [
                                    "Caracterização do Modelo",
                                    "Produção e Observações",
                                    "Composição Média do Modelo",
                                    "Controle de Habilitação"
                                ]
                                : ["Caracterização do Modelo"]
                        }
                    />
                    {getTabs()}
                </div>

            </div>
        </div>
    )
}