import { Container, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../../assets/Images/Lei_de_TICS.png';
import { useCompany } from '../../../hooks/useCompany';
import { useOAuthUser } from '../../../hooks/useOAuthUser';
import './styles.css';
import { BiExit } from 'react-icons/bi';
import { useCan } from '../../../hooks/useCan';
import { ExternalRoles } from '../../../Static/Roles';

const NavbarHome = () => {
    const navigate = useNavigate();
    const user = useOAuthUser();
    const { company } = useCompany();

    const Logout = () => {
        navigate("/Login");
    }
    const permissionTechnician = useCan({ rolesCan: ["Technician"] });
    const permissionExternal = useCan({ rolesCan: [...ExternalRoles] });
    
    return (
        <Navbar collapseOnSelect expand="lg" className='back-white p-0'>
            <Container fluid className='pe-0'>
                <Navbar.Brand className='p-0 margin-navbar' href={`/${company.serviceId}/home`}>
                    <img src={Logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className='justify-content-between h-100 d-flex' id="responsive-navbar-nav">
                    <Nav className="me-auto align-items-center justify-content-between">
                        <NavItem className='p-0 margin-navbar'>
                            <NavLink to={`/${company.serviceId}/home`}>Homepage</NavLink>
                        </NavItem>
                        <NavDropdown
                            title={
                                <span>
                                    I. Produtos
                                </span>
                            }
                            id="navbarScrollingNavDropdown"
                            className='p-0 margin-navbar'
                        >
                            <NavDropdown.Item
                                as={NavLink}
                                to={`/${company.serviceId}/${company.companyId}/products`}
                            >
                                I.1. Produtos e Modelos
                            </NavDropdown.Item>
                            <NavDropdown.Item className=' cursor-not-allowed' id='ppb'>
                                <p className='p-0  margin-navbar' id='ppb' style={{ fontWeight: 'bold' }}>I.2 PPB (Em Breve)</p>
                            </NavDropdown.Item>
                        </NavDropdown>
                        {permissionTechnician ?
                            <div>
                            </div> :
                            company.region && company.region === "SUFRAMA" || company.branchRegionHasSuframa && company.branchRegionHasSuframa === true ?
                                <NavDropdown
                                    title={
                                        <span>
                                            II. Faturamentos e Aquisições
                                        </span>
                                    }
                                    id="navbarScrollingNavDropdown"
                                    className='p-0 margin-navbar'
                                ><NavDropdown.Item
                                    as={NavLink}
                                    to={`/${company.serviceId}/${company.companyId}/invoicing`}
                                >
                                        II.1. Faturamentos
                                    </NavDropdown.Item><NavDropdown.Item
                                        as={NavLink}
                                        to={`/${company.serviceId}/${company.companyId}/acquisitions`}
                                    >
                                        II.2. Aquisições
                                    </NavDropdown.Item></NavDropdown>
                                : permissionTechnician ?
                                    <NavItem></NavItem> :
                                    <NavItem className='p-0 margin-navbar'><NavLink to={`/${company.serviceId}/${company.companyId}/invoicing`}>II. Faturamento</NavLink></NavItem>
                        }
                        {!permissionTechnician &&
                            <>
                                {!permissionExternal &&
                                <NavDropdown title={
                                    <span>
                                        III. Identificação Técnica
                                    </span>
                                }
                                    id="navbarScrollingNavDropdown"
                                    className='p-0 margin-navbar'
                                >
                                    <NavDropdown.Item
                                        as={NavLink}
                                        to={`/${company.serviceId}/internalProjects`}>
                                        III.1. Projetos Internos
                                    </NavDropdown.Item>
                                    <NavDropdown.Item className=' cursor-not-allowed' id='ppb'>
                                        <p className='p-0  margin-navbar' id='ppb' style={{ fontWeight: 'bold' }}>III.2 Projetos Externos (Em Breve)</p>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                }

                                {/*<NavItem className='p-0 inav margin-navbar'>
                                    III. Identificação Técnica
                                    <p className='breve'>  (Em Breve)</p>
                                </NavItem>*/}
                                <NavItem className='p-0 inav margin-navbar'>
                                    IV. Dispêndios
                                    <p className='breve'>  (Em Breve)</p>
                                </NavItem>
                                <NavItem className='p-0 margin-navbar'>
                                    <NavLink to={`/${company.serviceId}/credit`}>V. Crédito Financeiro</NavLink>
                                </NavItem>
                                <NavItem className='p-0 inav margin-navbar'>
                                    VI. RDA
                                    <p className='breve'>  (Em Breve) </p>
                                </NavItem>
                            </>
                        }
                    </Nav>

                    <div className='polygon-rectangle-wrap'>
                        <div className='polygon-rectangle'>
                            <Nav className='last-child'>
                                <NavDropdown
                                    title={
                                        <span>Olá, {user?.profile.given_name}</span>
                                    }
                                    id="navbarScrollingNavDropdown"
                                >
                                    {/* <NavDropdown.Item
                                        as={NavLink}
                                        to={`/managment`}
                                    >
                                        Painel de Controle
                                    </NavDropdown.Item> */}
                                    <NavDropdown.Item
                                        as={NavLink}
                                        to={`/true`}
                                    >
                                        Gestão de Ano Fiscal
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={NavLink}
                                        to={`/${company.serviceId}/fileManager`}
                                    >
                                        Gerenciador de Documentos
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={NavLink}
                                        to={`/${company.serviceId}/Logs`}
                                    >
                                        Logs
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <div className="d-flex align-items-center">
                                    <button className='button-exit' onClick={() => Logout()}>
                                        <BiExit size={20} className='cursor-pointer me-1' />
                                    </button>
                                </div>
                            </Nav>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavbarHome;
